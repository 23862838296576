import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  NavigationCancel,
  NavigationError,
  NavigationExtras,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
} from '@angular/router';

import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { filter, map, noop, switchMap, tap } from 'rxjs';

import { navigationActions } from '../actions/navigation.actions';

type ShouldReuseRoute = (future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot) => boolean;

@Injectable()
export class NavigationEffects implements OnInitEffects {
  public readonly handleNavigationChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(navigationActions.listenToRouteChange),
      switchMap(() =>
        this.router.events.pipe(
          filter(
            event =>
              event instanceof RouteConfigLoadStart ||
              event instanceof RouteConfigLoadEnd ||
              event instanceof NavigationCancel ||
              event instanceof NavigationError,
          ),
          map(event => {
            if (event instanceof RouteConfigLoadStart) {
              return navigationActions.increaseRouteLoadingCount();
            } else if (event instanceof RouteConfigLoadEnd) {
              return navigationActions.decreaseRouteLoadingCount();
            } else if (event instanceof NavigationCancel) {
              return navigationActions.decreaseRouteLoadingCount();
            } else if (event instanceof NavigationError) {
              return navigationActions.decreaseRouteLoadingCount();
            }
          }),
        ),
      ),
    ),
  );

  public readonly navigateTo$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.navigateTo),
        tap(action => {
          let extras: NavigationExtras = {};

          if (action.extras) {
            extras = { ...action.extras };
          }

          if (action.useRelativeRoute) {
            extras = { ...extras, relativeTo: this.route };
          }

          this.router.navigate(action.commands, extras);
        }),
      ),
    { dispatch: false },
  );

  /**
   * TODO: `routeReuseStrategy` is deprecating soon, find actual working solution.
   */
  public readonly reloadCurrentRoute$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(navigationActions.reloadCurrentRoute),
        tap(() => {
          const { shouldReuseRoute } = this.router.routeReuseStrategy;
          this.setRouteReuse(() => false);
          this.router.navigated = false;
          this.router
            .navigateByUrl(this.router.url)
            .catch(noop)
            .then(() => this.setRouteReuse(shouldReuseRoute));
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  public ngrxOnInitEffects(): Action {
    return navigationActions.listenToRouteChange();
  }

  private setRouteReuse(reuse: ShouldReuseRoute): void {
    this.router.routeReuseStrategy.shouldReuseRoute = reuse;
  }
}
