import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const logoutActions = createActionGroup({
  source: 'Auth - Logout',
  events: {
    logout: emptyProps(),
    logoutWithStandardRedirect: emptyProps(),
    logoutWithTerminalRedirect: emptyProps(),
    logoutSetPassword: props<{ token: string }>(),
  },
});
