import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { catchError, concatMap, exhaustMap, map, of } from 'rxjs';

import { V1_AuthService } from '@app/api/v1/auth/v1-auth.service';
import { navigationActions } from '@app/store/actions/navigation.actions';
import { loginByTokenActions } from '../actions/login-by-token.actions';
import { loginActions } from '../actions/login.actions';

@Injectable()
export class LoginByTokenEffects {
  public readonly loginByToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginByTokenActions.start),
      exhaustMap(({ token, redirectUrl }) =>
        this.v1_AuthService.tokenLogin({ token }).pipe(
          map(response => loginByTokenActions.success({ response: response.data, redirectUrl })),
          catchError(() => of(loginByTokenActions.error())),
        ),
      ),
    ),
  );

  public readonly handleLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginByTokenActions.success),
      concatMap(({ response, redirectUrl }) => {
        const actions: Action[] = [loginActions.success({ response, skipNavigation: !!redirectUrl })];

        if (redirectUrl) {
          actions.push(navigationActions.navigateTo({ commands: [redirectUrl] }));
        }

        return actions;
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private v1_AuthService: V1_AuthService,
  ) {}
}
