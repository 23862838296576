import { Injectable } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { CommonPwaInstallDialogComponent } from '../modals/common-pwa-install-dialog/common-pwa-install-dialog.component';
import { Platform } from '@angular/cdk/platform';
import { AuthService } from '../../../shared/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CommonPwaInstallService {
  private deferredPrompt: any;

  constructor(private dialogService: NbDialogService,
              private authService: AuthService,
              private platform: Platform
  ) {
    /* setTimeout(() => {
       const dialog = this.dialogService.open(CommonPwaInstallDialogComponent, {closeOnBackdropClick: false, hasBackdrop: false});
     }, 2222);*/
  }

  /*@HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    console.log('[DEFAULT] BEFORE INSTALL PROMPT');
    console.log(e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    // e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;

  }*/

  public initAppInstallPrompt() {
    // setTimeout(() => {
    if (this.platform.ANDROID) {
      console.log('[ANDROID]');
      window.addEventListener('beforeinstallprompt', (event: any) => {
        event.preventDefault();
        if (this.authService.getUserData()) {
          const dialog = this.dialogService.open(CommonPwaInstallDialogComponent,
            {closeOnBackdropClick: false, hasBackdrop: false, context: {device: 'android'}});
          dialog.componentRef.instance.installButtonClicked.subscribe(clicked => {
            event.prompt();
          });
        }
      });
    }
    if (this.platform.IOS) {
      console.log('[IOS]');
      if (!this.isInStandaloneMode()) {
        if (this.authService.getUserData()) {
          this.dialogService.open(CommonPwaInstallDialogComponent,
            {closeOnBackdropClick: false, hasBackdrop: false, context: {device: 'ios'}});
        }
      }
    }
    // }, 1000);
  }

  isInStandaloneMode(): boolean {
    // @ts-ignore
    return ('standalone' in window.navigator) && (window.navigator.standalone);

  }
}
