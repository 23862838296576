import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, concatMap, exhaustMap, filter, first, map, of, switchMap } from 'rxjs';

import { V1_AuthService } from '@app/api/v1/auth/v1-auth.service';
import { V1_AUTH_LOGIN_TYPE, V1_AUTH_USER_ROLE } from '@app/models/api/v1/auth/auth.enums';
import { navigationActions } from '@app/store/actions/navigation.actions';
import { notificationActions } from '@app/store/actions/notification.actions';
import { translateActions } from '@app/store/actions/translate.actions';
import { loginActions } from '../actions/login.actions';
import { persistanceActions } from '../actions/persistance.actions';
import { selectChangedLanguage } from '../selectors/login.selectors';

@Injectable()
export class LoginEffects {
  public readonly handleLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.start),
      exhaustMap(({ params }) =>
        this.v1_AuthService.standardLogin(params).pipe(
          map(response => {
            switch (response.data.login_type) {
              case V1_AUTH_LOGIN_TYPE.SET_PHONE_AVAILABLE:
              case V1_AUTH_LOGIN_TYPE.SET_PHONE_REQUIRED:
                return loginActions.setupMFA({ response: response.data });
              case V1_AUTH_LOGIN_TYPE.SMS_CODE:
                return loginActions.useMFA({ response: response.data });
              case V1_AUTH_LOGIN_TYPE.STANDARD_LOGIN:
                return loginActions.success({ response: response.data, message: response.message });
            }
          }),
          catchError((response: HttpErrorResponse) =>
            of(loginActions.error({ validationErrors: response.error.errors, errorMessage: response.error.message })),
          ),
        ),
      ),
    ),
  );

  public readonly persistTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.success),
      map(({ response: { refresh_token, access_token } }) =>
        persistanceActions.persistTokens({ tokens: { refreshToken: refresh_token, accessToken: access_token } }),
      ),
    ),
  );

  public readonly persistAdminLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.success),
      filter(({ asAdmin }) => asAdmin),
      map(() => persistanceActions.persistAdminLogin()),
    ),
  );

  public readonly notifySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.success),
      filter(({ message }) => !!message),
      map(({ message }) => notificationActions.success({ title: message })),
    ),
  );

  public readonly notifyError$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.error),
      filter(({ errorMessage }) => !!errorMessage),
      map(({ errorMessage }) => notificationActions.error({ title: errorMessage, translate: false })),
    ),
  );

  public readonly changeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.changedLanguageTo),
      map(({ language }) => translateActions.setLanguage({ language, skipPageReload: true })),
    ),
  );

  public readonly navigateToApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.success),
      filter(({ skipNavigation }) => !skipNavigation),
      switchMap(({ response: { user }, asAdmin }) =>
        this.store.select(selectChangedLanguage).pipe(
          first(),
          concatMap(language => {
            const actions: Action[] = [];

            if (!language || language === user.language) {
              actions.push(translateActions.setLanguage({ language: user.language, skipPageReload: true }));
            }

            if (
              (user.roles.includes(V1_AUTH_USER_ROLE.SLONEEK_ADMIN) || user.roles.includes(V1_AUTH_USER_ROLE.SLONEEK_TRANSLATOR)) &&
              !asAdmin
            ) {
              actions.push(navigationActions.navigateTo({ commands: ['/', 'admin'] }));
            } else {
              actions.push(navigationActions.navigateTo({ commands: ['/', 'app'] }));
            }

            return actions;
          }),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private v1_AuthService: V1_AuthService,
  ) {}
}
