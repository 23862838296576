import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonPwaUpdateAvailableDialogComponent } from './modals/common-pwa-update-available-dialog/common-pwa-update-available-dialog.component';
import { CommonCardModule } from '../common-card/common-card.module';
import { NbButtonModule, NbCheckboxModule, NbIconModule, NbProgressBarModule } from '@nebular/theme';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { CommonPwaInstallDialogComponent } from './modals/common-pwa-install-dialog/common-pwa-install-dialog.component';


@NgModule({
  declarations: [CommonPwaUpdateAvailableDialogComponent, CommonPwaInstallDialogComponent],
    imports: [
        CommonModule,
        BrowserAnimationsModule,
        CommonCardModule,
        NbButtonModule,
        NbIconModule,
        TranslateModule,
        NbProgressBarModule,
        NbCheckboxModule,
    ],
})
export class CommonPwaModule {
}
