import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { POST_V1_AuthTerminalLoginParams, V1AuthTerminal, V1AuthTerminalLogin } from '@app/models/api/v1/auth/auth.model';
import APIValidationError from '@app/models/api/validation-error.model';

export const terminalLoginActions = createActionGroup({
  source: 'Auth - Terminal Login',
  events: {
    start: props<{ params: POST_V1_AuthTerminalLoginParams }>(),
    success: props<{ response: V1AuthTerminalLogin }>(),
    error: props<{ validationErrors?: APIValidationError; errorMessage?: string }>(),
  },
});

export const terminalActions = createActionGroup({
  source: 'Auth - Terminal',
  events: {
    save: props<{ terminal: V1AuthTerminal }>(),
    remove: emptyProps(),
  },
});
