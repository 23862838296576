import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router, UrlTree } from '@angular/router';

import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { V1_AUTH_USER_ROLE } from '@app/models/api/v1/auth/auth.enums';
import { selectUserState } from '../store/selectors/user.selectors';

const isNotTerminal = (): (() => Observable<boolean | UrlTree>) => {
  return (): Observable<boolean | UrlTree> => {
    const store = inject(Store);
    const router = inject(Router);
    return store.select(selectUserState).pipe(
      filter(userState => userState.initialAuthAttempt),
      map(userState => userState.user),
      take(1),
      map(user => {
        if (user && user.roles.includes(V1_AUTH_USER_ROLE.TERMINAL)) {
          return router.createUrlTree(['/', 'terminal']);
        } else {
          return true;
        }
      }),
    );
  };
};

export const notTerminalCanActivateGuard: () => CanActivateFn = isNotTerminal;

export const notTerminalCanMatchGuard: () => CanMatchFn = isNotTerminal;
