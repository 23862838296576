import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { V1AuthTerminal } from '@app/models/api/v1/auth/auth.model';

export const persistanceActions = createActionGroup({
  source: 'Auth - Persistance',
  events: {
    removeTokens: emptyProps(),
    removeAdminLogin: emptyProps(),
    removeTerminal: emptyProps(),
    persistAdminLogin: emptyProps(),
    persistTerminal: props<{ terminal: V1AuthTerminal }>(),
    persistTokens: props<{
      tokens: {
        accessToken: string;
        refreshToken: string;
      };
    }>(),
  },
});

export const initialPersistanceActions = createActionGroup({
  source: 'Auth - Initial Persistance',
  events: {
    load: emptyProps(),
    loadTerminalLoaded: props<{ terminal: V1AuthTerminal }>(),
    loadTerminalFailed: emptyProps(),
    isAdminLoginLoaded: emptyProps(),
    isAdminLoginFailed: emptyProps(),
    tokensFailed: emptyProps(),
    tokensLoaded: props<{
      accessToken: string;
      refreshToken: string;
    }>(),
  },
});
