import { fadeInUpOnEnterAnimation, fadeOutDownAnimation } from 'angular-animations';
import { HostBinding, HostListener, Directive, OnDestroy } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { SubscriberComponent } from '../subscriber.component';

export const sharedDialogAnimation = [
  fadeInUpOnEnterAnimation({duration: 200}),
  fadeOutDownAnimation({duration: 200})
];

@Directive()
export class SharedDialog extends SubscriberComponent {
  // TODO HANDLE BACKDROP
  public closeParams: any;

  @HostBinding('@fadeInUpOnEnter') inAnimation = false;
  @HostBinding('@fadeOutDown') outAnimation = false;
  @HostBinding('style.display') backgroundColor = 'block';
  @HostBinding('style.margin') margin = '1rem';

  /*@HostListener('@fadeInUpOnEnter.done')
  enterAnimationDone() {
    console.log('done');
  }
*/
  constructor(protected dialogRef: NbDialogRef<SharedDialog>, disableBackdropClick?: boolean) {
    super();
    if (!disableBackdropClick) {
      this.initOnBackdropClickListener();
    }

  }


  /*
    @HostListener('@fadeInUpOnEnter.start', ['$event'])
    openedModal(event) {
      if (event && event.totalTime) {
      }
    }*/

  @HostListener('@fadeOutDown.done', ['$event'])
  closedModal(event) {
    if (event && event.totalTime) {
      this.dialogRef.close(this.closeParams);
    }
  }

  @HostListener('@fadeInUpOnEnter.done', ['$event'])
  fadeInAnimationIsDone(event) {
  }

  private initOnBackdropClickListener() {
    // changed onBackdropClick to onClose
    const sub = this.dialogRef?.onBackdropClick.subscribe(res => {
      this.closeDialog();
      sub.unsubscribe();
    });
  }

  public closeDialog(data?: any) {
    if (data) {
      this.closeParams = data;
    }
    this.outAnimation = true;
  }
}
