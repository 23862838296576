/**
 * All available user roles in the system.
 * Each role has a different set of permissions.
 */
export enum V1_AUTH_USER_ROLE {
  ADMIN = 'admin',
  OWNER = 'owner',
  TERMINAL = 'terminal',
  MANAGER = 'manager',
  SLONEEK_ADMIN = 'sloneek',
  SLONEEK_TRANSLATOR = 'sloneek_translator',
  ASSET_MANAGER = 'property_administrator',
  DOCUMENT_MANAGER = 'document_manager',
  REPORTING = 'reporting',
  USER = 'user',
  ATS_ADMINISTRATOR = 'ats_administrator',
  ATS_HIRING_MANAGER = 'ats_hiring_manager',
  PLANNING_WRITER = 'planning_writer',
  VACATION_WRITE = 'vacation_writer',
  ATTENDANCE_WRITER = 'attendance_writer',
  PROJECT_ADMINISTRATOR = 'project_administrator',
  PROJECT_MANAGER = 'project_manager',
  OMBUDSMAN = 'ombudsman',
  ABSENCE_APPROVER = 'absence_approver',
  EVALUATION_MANAGER = 'evaluation_manager',
}

/**
 * Contains enabled/disabled feature of the app depending on the company setup.
 */
export enum V1_AUTH_COMPANY_FEATURES {
  CORE_EXTENDED = 'core_extended',
  ABSENCES = 'absences',
  PLANNING = 'planning',
  ATTENDANCE = 'attendance',
  DOCUMENTS = 'documents',
  PROPERTY = 'property',
  CHECKLIST = 'checklist',
  COMPETENCE = 'competence',
  PROJECT = 'project',
  ATS = 'ats',
  SKILL_SET = 'skill_set',
  ENGAGEMENT = 'engagement',
  QUESTIONNAIRE = 'questionnaire',
  PERFORMANCE = 'performance',
  PEOPLE_ANALYTICS = 'people_analytics',
  OKTA = 'okta',
  MFA = 'mfa',
  SURVEYS = 'surveys',
}

/**
 * App modules available to the user based on his roles and permissions.
 */
export enum V1_AUTH_MODULES {
  IS_VACATION_ALLOWED = 'is_vacation_allowed',
  IS_PLANNING_ALLOWED = 'is_planning_allowed',
  IS_ATTENDANCE_ALLOWED = 'is_attendance_allowed',
  IS_DOCUMENTS_ALLOWED = 'is_documents_allowed',
  IS_PROPERTIES_ALLOWED = 'is_properties_allowed',
  IS_CHECKLIST_ALLOWED = 'is_checklist_allowed',
  IS_COMPETENCE_ALLOWED = 'is_competence_allowed',
  IS_PROJECT_ALLOWED = 'is_project_allowed',
  IS_ATS_ALLOWED = 'is_ats_allowed',
  IS_SKILLSET_ALLOWED = 'is_skillset_allowed',
  IS_ENGAGEMENT_ALLOWED = 'is_engagement_allowed',
  IS_QUESTIONNAIRE_ALLOWED = 'is_questionnaire_allowed',
  IS_PERFORMANCE_ALLOWED = 'is_performance_allowed',
  IS_PEOPLE_ANALYTICS_ALLOWED = 'is_people_analytics_allowed',
  IS_SURVEYS_ALLOWED = 'is_surveys_allowed',
}

export enum V1_AUTH_COMPANY_MODULES {
  IS_ABSENCE_MODULE_VISIBLE = 'is_absence_module_visible',
  IS_ATS_MODULE_VISIBLE = 'is_ats_module_visible',
  IS_ATTENDANCE_MODULE_VISIBLE = 'is_attendance_module_visible',
  IS_CHAT_MODULE_VISIBLE = 'is_chat_module_visible',
  IS_CHECKLIST_MODULE_VISIBLE = 'is_checklist_module_visible',
  IS_COMPETENCE_MODULE_VISIBLE = 'is_competence_module_visible',
  IS_DOCUMENT_MODULE_VISIBLE = 'is_document_module_visible',
  IS_PLANNING_MODULE_VISIBLE = 'is_planning_module_visible',
  IS_PROJECT_MODULE_VISIBLE = 'is_project_module_visible',
  IS_PROPERTY_MODULE_VISIBLE = 'is_property_module_visible',
  IS_SKILL_SET_MODULE_VISIBLE = 'is_skill_set_module_visible',
  IS_QUESTIONNAIRE_MODULE_VISIBLE = 'is_questionnaire_module_visible',
  IS_PERFORMANCE_MODULE_VISIBLE = 'is_performance_module_visible',
  IS_PEOPLE_ANALYTICS_MODULE_VISIBLE = 'is_people_analytics_module_visible',
  IS_SURVEYS_MODULE_VISIBLE = 'is_surveys_module_visible',
}

export enum V1_AUTH_LOGIN_TYPE {
  STANDARD_LOGIN = 'standard_login',
  SET_PHONE_AVAILABLE = 'set_phone_available',
  SET_PHONE_REQUIRED = 'set_phone_required',
  SMS_CODE = 'sms_code',
}
