import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, exhaustMap, filter, first, map, switchMap, of } from 'rxjs';

import { V1_AuthService } from '@app/api/v1/auth/v1-auth.service';
import { navigationActions } from '@app/store/actions/navigation.actions';
import { loginActions } from '../actions/login.actions';
import { mfaCodeSubmitActions, mfaResendCodeActions, mfaSetupActions, mfaSkipActions } from '../actions/mfa.actions';
import { selectMFAToken } from '../selectors/mfa.selectors';

@Injectable()
export class MFAEffects {
  public readonly redirectToMFACodePage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.useMFA, mfaSetupActions.success),
      map(() => navigationActions.navigateTo({ commands: ['/', 'auth', 'login', 'mfa-enter-code'] })),
    ),
  );

  public readonly redirectToMFASetupPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginActions.setupMFA),
      map(() => navigationActions.navigateTo({ commands: ['/', 'auth', 'login', 'mfa-set-phone'] })),
    ),
  );

  public readonly submitCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mfaCodeSubmitActions.start),
      switchMap(({ code }) =>
        this.store.select(selectMFAToken).pipe(
          first(),
          filter(token => !!token),
          exhaustMap(token =>
            this.v1_AuthService.verifyMFACode({ token, code }).pipe(
              map(response => mfaCodeSubmitActions.success({ response: response.data })),
              catchError(() => of(mfaCodeSubmitActions.error())),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly resendCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mfaResendCodeActions.start),
      switchMap(() =>
        this.store.select(selectMFAToken).pipe(
          first(),
          filter(token => !!token),
          exhaustMap(token =>
            this.v1_AuthService.resendMFACode({ token }).pipe(
              map(response => mfaResendCodeActions.success({ response: response.data })),
              catchError(() => of(mfaResendCodeActions.error())),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly skipMFA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mfaSkipActions.start),
      switchMap(({ isPermament }) =>
        this.store.select(selectMFAToken).pipe(
          first(),
          filter(token => !!token),
          exhaustMap(token =>
            this.v1_AuthService.skipMFA({ token, is_permanently_skipped: isPermament }).pipe(
              map(response => mfaSkipActions.success({ response: response.data })),
              catchError(() => of(mfaSkipActions.error())),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly setupMFA$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mfaSetupActions.start),
      switchMap(({ phone, countryCode }) =>
        this.store.select(selectMFAToken).pipe(
          first(),
          filter(token => !!token),
          exhaustMap(token =>
            this.v1_AuthService.setupMFA({ token, phone_number: phone, country_code: countryCode }).pipe(
              map(response => mfaSetupActions.success({ response: response.data })),
              catchError(() => of(mfaSetupActions.error())),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly loginToApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(mfaCodeSubmitActions.success, mfaSkipActions.success),
      map(({ response }) => loginActions.success({ response })),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private v1_AuthService: V1_AuthService,
  ) {}
}
