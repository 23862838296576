export interface LocalizationLang {
  name: string;
  icon: string;
  code: LANGUAGE_CODE;
}

export enum LANGUAGE_CODE {
  EN = 'en',
  DE = 'de',
  ES = 'es',
  IT = 'it',
  PL = 'pl',
  SK = 'sk',
  CS = 'cs',
  HU = 'hu',
  FR = 'fr',
  // this language is just for testing - it displays translate keys on dev envs
  TESTING_LANGUAGE_CODE = 'test',
}
