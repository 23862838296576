/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { createSelector } from '@ngrx/store';

import { selectAuthState } from '../auth.reducers';

export const selectUserState = createSelector(selectAuthState, state => state.user);

export const selectIsAdminLoggedIn = createSelector(selectUserState, state => state.isAdminLogged);

export const selectUser = createSelector(selectUserState, state => state.user);

export const selectUserUuid = createSelector(selectUser, state => (state ? state.uuid : null));

export const selectUserCompany = createSelector(selectUser, state => (state ? state.company : null));

export const selectUserCompanyName = createSelector(selectUserCompany, state => (state ? state.name : null));

/**
 * Best to use as filter for initial auth operations, turns true whenever user
 * object is loaded or the app has attempted to load it.
 */
export const selectInitialAuthAttempt = createSelector(selectUserState, state => state.initialAuthAttempt);

export const selectIsLoadingUserInfo = createSelector(selectUserState, state => state.isLoadingUserInfo);

export const selectHasCompanyIncreasedPasswordProtection = createSelector(
  selectUserCompany,
  state => state.security_settings?.increased_password_protection ?? false,
);

export const selectUserCompanyCountry = createSelector(selectUserCompany, state => (state ? state.country : null));

export const selectUserCompanyCountryCode = createSelector(selectUserCompanyCountry, state => (state ? state.country_code : null));
