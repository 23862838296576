import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { POST_V1_AuthLogoutHostUserResponse } from '@app/models/api/v1/auth/auth.model';
import {
  POST_V1_BathLoginAsCompanyOwnerParams,
  POST_V1_BathLoginAsCompanyOwnerResponse,
  POST_V1_BathLoginAsUserParams,
  POST_V1_BathLoginAsUserResponse,
} from '@app/models/api/v1/bath/bath.model';

export const adminLoginAsUserActions = createActionGroup({
  source: 'Auth - Admin Login As User',
  events: {
    start: props<{ params: POST_V1_BathLoginAsUserParams }>(),
    success: props<{ response: POST_V1_BathLoginAsUserResponse }>(),
    error: emptyProps(),
  },
});

export const adminLoginAsOwnerActions = createActionGroup({
  source: 'Auth - Admin Login As Owner',
  events: {
    start: props<{ params: POST_V1_BathLoginAsCompanyOwnerParams }>(),
    success: props<{ response: POST_V1_BathLoginAsCompanyOwnerResponse }>(),
    error: emptyProps(),
  },
});

export const adminLogoutActions = createActionGroup({
  source: 'Auth - Admin Logout',
  events: {
    start: emptyProps(),
    success: props<{ response: POST_V1_AuthLogoutHostUserResponse }>(),
    error: emptyProps(),
  },
});
