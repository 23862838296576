import { AfterViewInit, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { NbIconLibraries } from '@nebular/theme';
import { Store } from '@ngrx/store';
import { fadeInOnEnterAnimation, fadeOutOnLeaveAnimation } from 'angular-animations';
import { combineLatest } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { debounceTime, map, take } from 'rxjs/operators';

import { selectInitialAuthAttempt } from './auth/store/selectors/user.selectors';
import { CommonPwaInstallService } from './common-modules/common-pwa/services/common-pwa-install.service';
import { CommonPwaUpdateService } from './common-modules/common-pwa/services/common-pwa-update.service';
import { SlnkGoogleTagManagerService } from './services/integrations/slnk-google-tag-manager.service';
import { SupportChatService } from './services/integrations/support-chat.service';
import { UserflowService } from './services/integrations/userflow.service';
import { SubscriberComponent } from './shared/classes/subscriber.component';
import { selectIsNavigationLoadingModules } from './store/selectors/navigation.selectors';

@Component({
  selector: 'slnk-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeInOnEnterAnimation({ delay: 0 }), fadeOutOnLeaveAnimation()],
})
export class AppComponent extends SubscriberComponent implements OnInit, AfterViewInit {
  public readonly showLoadingIndicator$ = combineLatest({
    isLoadingModules: this.store.select(selectIsNavigationLoadingModules),
    toBeAuthenticated: this.store.select(selectInitialAuthAttempt).pipe(map(attempt => !attempt)),
  }).pipe(map(({ isLoadingModules, toBeAuthenticated }) => isLoadingModules || toBeAuthenticated));

  constructor(
    public router: Router,
    private iconLibraries: NbIconLibraries,
    private appUpdateService: CommonPwaUpdateService,
    private appInstallService: CommonPwaInstallService,
    private crispCustomizationService: SupportChatService,
    private userFlowService: UserflowService,
    private slnkGtmService: SlnkGoogleTagManagerService,
    private store: Store,
  ) {
    super();
    this.iconLibraries.registerFontPack('solid', { packClass: 'fas', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('regular', { packClass: 'far', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('light', { packClass: 'fal', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('duotone', { packClass: 'fad', iconClassPrefix: 'fa' });
    this.iconLibraries.registerFontPack('brands', { packClass: 'fab', iconClassPrefix: 'fa' });
    this.iconLibraries.setDefaultPack('light');
  }

  public ngOnInit(): void {
    this.crispCustomizationService.addHideCrispClass();
    this.userFlowService.userFlowInit();
    this.globalLazyLoadingIndicator();
  }

  public ngAfterViewInit(): void {
    this.firstAppLoadNavigationEnd();
  }

  /**
   * Check if app is loading new module
   */
  public globalLazyLoadingIndicator(): void {
    // The Router emits special events for "loadChildren" configuration loading. We
    // just need to listen for the Start and End events in order to determine if we
    // have any pending configuration requests.
    this.subs.push(
      this.router.events.subscribe({
        next: (event): void => {
          // PUSH GTM ROUTER CHANGES
          if (event instanceof NavigationEnd) {
            this.slnkGtmService.routerNavigationTracking(event);
            this.crispCustomizationService.trackRouteChance(event.url);
          }
        },
      }),
    );
  }

  public firstAppLoadNavigationEnd(): void {
    this.subs.push(
      this.router.events
        .pipe(
          filter(event => event instanceof NavigationEnd),
          debounceTime(400),
          take(1),
        )
        .subscribe(() => {
          this.appInstallService.initAppInstallPrompt();
          this.appUpdateService.initAppUpdateCheck();
        }),
    );
  }
}
