import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { POST_V1_AuthPasswordSettingsParams } from '@app/models/api/v1/auth/auth.model';
import { SecuritySettings } from '@app/models/shared/security-settings.model';

export const passwordSettingsActions = createActionGroup({
  source: 'Auth - Get Password Settings',
  events: {
    start: props<{ params: POST_V1_AuthPasswordSettingsParams }>(),
    success: props<{ response: SecuritySettings }>(),
    error: emptyProps(),
  },
});
