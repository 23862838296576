export const BACKEND_DAY_FORMAT = 'YYYY-MM-DD';

export const IMAGE_FILE_UPLOAD_TYPES = '.jpg, .jpeg, .png';
export const PDF_FILE_UPLOAD_TYPE = 'application/pdf';
export const DOCUMENT_FILE_UPLOAD_TYPES = '.doc, .docx ';
export const PRESENTATION_FILE_UPLOAD_TYPES = '.ppt, .pptx';
export const TABLE_FILE_UPLOAD_TYPES = '.xls, .xlsx, .csv';

export const PLANNING_EVENT_DOCUMENTS_UPLOAD_TYPES =
  IMAGE_FILE_UPLOAD_TYPES +
  ',' +
  PDF_FILE_UPLOAD_TYPE +
  ', ' +
  DOCUMENT_FILE_UPLOAD_TYPES +
  ', ' +
  PRESENTATION_FILE_UPLOAD_TYPES +
  ', ' +
  TABLE_FILE_UPLOAD_TYPES;

export const IMAGE_MAX_FILE_UPLOAD_SIZE_MB = 2;

export const LANGUAGE_LOCAL_STORAGE_KEY = 'local-translate';

export const JWT_TOKEN_STORAGE = 'sloneek-access-token';
export const JWT_REFRESH_TOKEN_STORAGE = 'sloneek-refresh-token';
// TODO: remove if not needed
export const JWT_SOCKET_TOKEN_STORAGE = 'sloneek-socket-token';
export const ADMIN_LOGGED = 'sloneek-is-admin-logged';
export const USER_DATA = 'sloneek-user';
export const TERMINAL_DATA = 'sloneek-terminal';
export const INITIAL_LANGUAGE = 'en';

export const GRID_TYPE_EXTENDED = 'gridTypeExtended';

export enum DEFAULT_PHOTOS {
  ASSETS_PHOTO = './assets/images/static/box-open-solid.svg',
  COMPANY_PHOTO = './assets/images/static/building-solid.svg',
  USER_PHOTO = './assets/images/static/user-regular.png',
}
