import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { concatMap, tap } from 'rxjs';

import { PersistanceService } from '@app/auth/services/persistance.service';
import { initialPersistanceActions, persistanceActions } from '../actions/persistance.actions';

@Injectable()
export class PersistanceEffects implements OnInitEffects {
  public readonly persistTokens$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(persistanceActions.persistTokens),
        tap(({ tokens: { refreshToken, accessToken } }) => {
          this.persistanceService.persistAccessToken(accessToken);
          this.persistanceService.persistRefreshToken(refreshToken);
        }),
      ),
    { dispatch: false },
  );

  public readonly persistAdminLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(persistanceActions.persistAdminLogin),
        tap(() => this.persistanceService.persistAdminLogin(true)),
      ),
    { dispatch: false },
  );

  public readonly persistTerminal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(persistanceActions.persistTerminal),
        tap(({ terminal }) => this.persistanceService.persistTerminal(terminal)),
      ),
    { dispatch: false },
  );

  public readonly removeTokens$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(persistanceActions.removeTokens),
        tap(() => {
          this.persistanceService.removeAccessToken();
          this.persistanceService.removeRefreshToken();
        }),
      ),
    { dispatch: false },
  );

  public readonly removeAdminLogin$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(persistanceActions.removeAdminLogin),
        tap(() => this.persistanceService.removeAdminLogin()),
      ),
    { dispatch: false },
  );

  public readonly removeTerminal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(persistanceActions.removeTerminal),
        tap(() => this.persistanceService.removeTerminal()),
      ),
    { dispatch: false },
  );

  /**
   * Initial load of all dependency tokens from local storage.
   */
  public readonly initialLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initialPersistanceActions.load),
      concatMap(() => {
        const actions: Action[] = [];

        /* Handle auth tokens */
        try {
          const accessToken = this.persistanceService.getAccessToken();
          const refreshToken = this.persistanceService.getRefreshToken();
          if (accessToken && refreshToken) {
            actions.push(initialPersistanceActions.tokensLoaded({ accessToken, refreshToken }));
          } else {
            throw new Error('No tokens found in storage.');
          }
        } catch (error) {
          actions.push(initialPersistanceActions.tokensFailed(), persistanceActions.removeTokens());
        }

        /* Try to load admin login */
        try {
          const isAdminLogin = this.persistanceService.getAdminLogin();
          if (isAdminLogin) {
            actions.push(initialPersistanceActions.isAdminLoginLoaded());
          }
        } catch (_) {
          actions.push(initialPersistanceActions.isAdminLoginFailed(), persistanceActions.removeAdminLogin());
        }

        /* Try to load terminal */
        try {
          const terminal = this.persistanceService.getTerminal();
          if (terminal) {
            actions.push(initialPersistanceActions.loadTerminalLoaded({ terminal }));
          }
        } catch (_) {
          actions.push(initialPersistanceActions.loadTerminalFailed(), persistanceActions.removeTerminal());
        }

        return actions;
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private persistanceService: PersistanceService,
  ) {}

  /** Load all tokens on app init */
  public ngrxOnInitEffects(): Action {
    return initialPersistanceActions.load();
  }
}
