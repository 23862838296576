import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';

import { V1_AuthService } from '@app/api/v1/auth/v1-auth.service';
import { setPasswordActions } from '../actions/set-password.actions';

@Injectable()
export class SetPasswordEffects {
  public readonly setPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(setPasswordActions.start),
      exhaustMap(({ params }) =>
        this.v1_AuthService.setNewPassword(params).pipe(
          map(() => setPasswordActions.success()),
          catchError(() => of(setPasswordActions.error())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private v1_AuthService: V1_AuthService,
  ) {}
}
