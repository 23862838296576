<slnk-common-card>
  <slnk-common-card-body>
    <div class="row align-items-center">
      <div class="col-auto py-1">
        <img class="w-100 install_logo" src="../../../../../assets/icons/icon-128x128.png">
      </div>
      <div class="col px-0">
        <p class="mb-0">
          <ng-container [ngSwitch]="device">
            <ng-container *ngSwitchCase="'ios'">
              <ng-container *ngIf="browser !== 'Safari'; else SAFARIINIOS">
                {{'fe.common_modules.pwa.ios_message' | translate}}
              </ng-container>
            </ng-container>
            <ng-container *ngSwitchCase="'android'">
              <span class="caption-2">
                {{'fe.common_modules.pwa.android_message' | translate}}
              </span>
              <button nbButton ghost size="small" status="primary"
                      (click)="installButtonClicked.emit()">
                {{'fe.common_modules.pwa.android_button_install' | translate}}
              </button>
            </ng-container>
            <ng-container *ngSwitchCase="'android-add'">
              {{'fe.common_modules.pwa.android-add_message' | translate}}
            </ng-container>
            </ng-container>
        </p>
      </div>
      <div class="col-auto">
        <div (click)="closeDialog()">
          <nb-icon icon="times">
          </nb-icon>
        </div>
      </div>
    </div>
  </slnk-common-card-body>
</slnk-common-card>

<ng-template #SAFARIINIOS>
  {{'fe.common_modules.pwa.android-add_message' | translate}}
</ng-template>

