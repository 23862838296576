import { Injectable } from '@angular/core';
import { SLONEEK_THEME } from '../../../shared/enums/sloneek-theme';

@Injectable({
  providedIn: 'root'
})
export class ThemeStorageService {
  private themeKey: string = 'theme_color';

  constructor() {
  }

  getStoredTheme(): SLONEEK_THEME {
    const storedTheme: string = localStorage.getItem(this.themeKey);
    return storedTheme ? (storedTheme as SLONEEK_THEME) : SLONEEK_THEME.LIGHT;
  }

  setStoredTheme(theme: SLONEEK_THEME) {
    localStorage.setItem(this.themeKey, theme);
  }
}
