import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';

import { TranslateLoader } from '@ngx-translate/core';
import * as pako from 'pako';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@app/environments/environment';
import { LANGUAGE_CODE } from '@app/shared/models/localization-lang';

export const token = new InjectionToken('string');

@Injectable()
export class CustomTranslateLoader implements TranslateLoader {
  constructor(private http: HttpClient) {}

  public getTranslation(lang: string): Observable<{ [translateKey: string]: string }> {
    if (lang === LANGUAGE_CODE.TESTING_LANGUAGE_CODE) {
      return of({});
    }
    return this.http.get(`${environment.translatesS3Link}/translate_fe/${lang}`, { responseType: 'arraybuffer' }).pipe(
      map(response => {
        const inflatedData = pako.inflate(response, { to: 'string' });
        return JSON.parse(inflatedData);
      }),
      catchError(() => {
        return this.http.get(`${environment.translatesUrl}?locale=${lang}&category=frontend`);
      }),
    );
  }
}
