import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { interval } from 'rxjs';

import { SubscriberComponent } from '../shared/classes/subscriber.component';

@Component({
  selector: 'slnk-auth-page-under-construction-component',
  templateUrl: './auth-page-under-construction-component.component.html',
  styleUrls: ['./auth-page-under-construction-component.component.scss'],
})
export class AuthPageUnderConstructionComponentComponent extends SubscriberComponent implements OnInit {
  constructor(private router: Router) {
    super();
  }

  public ngOnInit(): void {
    this.subs.push(
      interval(10000).subscribe(() => {
        this.router.navigate(['/']);
      }),
    );
  }
}
