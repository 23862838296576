import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

export const InterceptorSkipUserLocale = 'X-Skip-user-locale';

@Injectable()
export class UserLocaleInterceptor implements HttpInterceptor {
  constructor(private translateService: TranslateService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.headers.has(InterceptorSkipUserLocale)) {
      const headers = request.headers.delete(InterceptorSkipUserLocale);
      return next.handle(request.clone({ headers }));
    }
    const currentLanguage = this.translateService.currentLang;
    if (currentLanguage) {
      request = request.clone({
        setHeaders: {
          'X-User-Locale': ` ${currentLanguage}`,
        },
      });
    }
    return next.handle(request);
  }
}
