import { LOCALE_ID, Provider } from '@angular/core';
import { LocalizationService } from './localization.service';

export class LocaleId extends String {
  constructor(private localeService: LocalizationService) {
    super();
  }

  toString(): string {
    return this.localeService.locale;
  }

  valueOf(): string {
    return this.toString();
  }
}

export const LocaleProvider: Provider = {
  provide: LOCALE_ID,
  useClass: LocaleId,
  deps: [LocalizationService],
};
