import { createReducer, on } from '@ngrx/store';

import APIValidationError from '@app/models/api/validation-error.model';
import Nullable from '@app/models/typescript/nullable.model';
import { loginActions } from '../actions/login.actions';
import { logoutActions } from '../actions/logout.actions';

export type LoginState = {
  isLoggingIn: boolean;
  errorMessage: Nullable<string>;
  validationErrors: Nullable<APIValidationError>;
  changedLanguageTo: Nullable<string>;
};

export const initialLoginState: LoginState = {
  isLoggingIn: false,
  errorMessage: null,
  validationErrors: null,
  changedLanguageTo: null,
};

export const loginReducer = createReducer(
  initialLoginState,
  on(
    loginActions.changedLanguageTo,
    (state, { language }): LoginState => ({
      ...state,
      changedLanguageTo: language,
    }),
  ),
  on(
    loginActions.start,
    (state): LoginState => ({
      ...state,
      isLoggingIn: true,
    }),
  ),
  on(
    loginActions.useMFA,
    loginActions.setupMFA,
    loginActions.success,
    (state): LoginState => ({
      ...state,
      isLoggingIn: false,
    }),
  ),
  on(
    loginActions.error,
    (state, { validationErrors, errorMessage }): LoginState => ({
      ...state,
      isLoggingIn: false,
      validationErrors: validationErrors ?? null,
      errorMessage: errorMessage ?? null,
    }),
  ),
  on(
    logoutActions.logout,
    (): LoginState => ({
      ...initialLoginState,
    }),
  ),
);
