import { inject, Injectable } from '@angular/core';

import { Actions, concatLatestFrom, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { filter, map, switchMap } from 'rxjs';

import { SingleTenantService } from '@app/services/single-tenant.service';
import { singleTenantActions } from '../actions/single-tenant.actions';
import { selectIsSingleTenantApp } from '../selectors/single-tenant.selectors';

/**
 * Whenever the app is deployed to customer's infrastructure,
 * it is deployed as a single tenant app. And effects bellow
 * should be triggered to initialize the app and single tenant
 * configuration.
 *
 * Single tenant config is responsible for various settings
 * that are specific to the customer's infrastructure. Such as
 * login methods, backend API url, etc.
 */
@Injectable()
export class SingleTenantEffects implements OnInitEffects {
  public readonly actions$ = inject(Actions);
  public readonly store = inject(Store);

  private readonly _singleTenantService = inject(SingleTenantService);

  public readonly initializeSingleTenant$ = createEffect(() =>
    this.actions$.pipe(
      ofType(singleTenantActions.initialize),
      concatLatestFrom(() => this.store.select(selectIsSingleTenantApp)),
      filter(([, isSingleTenant]) => Boolean(isSingleTenant)),
      map(() => singleTenantActions.loadConfig()),
    ),
  );

  public readonly loadSingleTenantConfig$ = createEffect(() =>
    this.actions$.pipe(
      ofType(singleTenantActions.loadConfig),
      switchMap(() => this._singleTenantService.initSingleTenantApp()),
      map(config =>
        config
          ? singleTenantActions.configLoaded({
              config: {
                isRegularLoginDisabled: config.is_regular_login_disabled,
                isGoogleLoginDisabled: config.is_google_login_disabled,
                isAzureLoginDisabled: config.is_azure_login_disabled,
                isOktaLoginDisabled: config.is_okta_login_disabled,
                atsPortalURL: config.ats_portal_url,
                backendURL: config.backed_url,
              },
            })
          : singleTenantActions.missingConfig(),
      ),
    ),
  );

  public ngrxOnInitEffects(): Action {
    return singleTenantActions.initialize();
  }
}
