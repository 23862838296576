import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Route, Router, UrlSegment, UrlTree } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { selectUserState } from '../store/selectors/user.selectors';

const isAuthorized = (store: Store): Observable<boolean> =>
  store.pipe(
    select(selectUserState),
    filter(userState => userState.initialAuthAttempt),
    map(userState => !!userState.user),
    take(1),
  );

export const authCanActivateGuard = (): CanActivateFn => {
  return (): Observable<boolean | UrlTree> => {
    const store = inject(Store);
    const router = inject(Router);
    return isAuthorized(store).pipe(
      map(isAuthorized => {
        if (!isAuthorized) {
          return router.createUrlTree(['/', 'auth', 'login']);
        } else {
          return isAuthorized;
        }
      }),
    );
  };
};

export const authCanMatchGuard = (): CanMatchFn => {
  return (_: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> => {
    const store = inject(Store);
    const router = inject(Router);
    return isAuthorized(store).pipe(
      map(isAuthorized => {
        if (!isAuthorized) {
          const queryParams = router.getCurrentNavigation().extractedUrl.queryParams;
          const query = [];
          if (queryParams) {
            Object.keys(queryParams).forEach(key => {
              query.push(key + '=' + queryParams[key]);
            });
          }
          return router.createUrlTree(['/', 'auth'], {
            queryParams: {
              url: segments ? segments.map(item => item.path).join('/') + (query.length > 0 ? `?${query.join('&')}` : '') : null,
            },
            queryParamsHandling: 'merge',
          });
        } else {
          return isAuthorized;
        }
      }),
    );
  };
};
