import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, Router, UrlTree } from '@angular/router';

import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

import { V1_AUTH_USER_ROLE } from '@app/models/api/v1/auth/auth.enums';
import { selectUserState } from '../store/selectors/user.selectors';

const isAnonymousUser = (store: Store, router: Router): Observable<boolean | UrlTree> =>
  store.pipe(
    select(selectUserState),
    filter(userState => userState.initialAuthAttempt),
    map(userState => [!userState.user, userState.user?.roles.includes(V1_AUTH_USER_ROLE.TERMINAL) ?? false] as [boolean, boolean]),
    take(1),
    map(([isAnonymous, isTerminal]) => {
      if (!isAnonymous) {
        return isTerminal ? router.createUrlTree(['/', 'terminal']) : router.createUrlTree(['/', 'app', 'dashboard']);
      } else {
        return isAnonymous;
      }
    }),
  );

export const anonymousCanActivateGuard = (): CanActivateFn => {
  return (): Observable<boolean | UrlTree> => {
    const store = inject(Store);
    const router = inject(Router);
    return isAnonymousUser(store, router);
  };
};

export const anonymousCanMatchGuard = (): CanMatchFn => {
  return (): Observable<boolean | UrlTree> => {
    const store = inject(Store);
    const router = inject(Router);
    return isAnonymousUser(store, router);
  };
};
