import { createReducer, on } from '@ngrx/store';

import { loginByTokenActions } from '../actions/login-by-token.actions';

export type LoginByTokenState = {
  isLoggingIn: boolean;
  hasError: boolean;
};

export const initialLoginByTokenState: LoginByTokenState = {
  isLoggingIn: false,
  hasError: false,
};

export const loginByTokenReducer = createReducer(
  initialLoginByTokenState,
  on(
    loginByTokenActions.start,
    (state): LoginByTokenState => ({
      ...state,
      isLoggingIn: true,
      hasError: false,
    }),
  ),
  on(
    loginByTokenActions.error,
    (state): LoginByTokenState => ({
      ...state,
      isLoggingIn: false,
      hasError: true,
    }),
  ),
  on(
    loginByTokenActions.success,
    (state): LoginByTokenState => ({
      ...state,
      isLoggingIn: false,
    }),
  ),
);
