import { Component, EventEmitter, Input, Output } from '@angular/core';

import { noop } from 'lodash';

export interface CommonCardEvent {
  event: COMMON_CARD_EVENT_TYPE;
}

export enum COMMON_CARD_EVENT_TYPE {
  CLOSE = 'close',
}

@Component({
  selector: 'slnk-common-card-header',
  templateUrl: './common-card-header.component.html',
  styleUrls: ['./common-card-header.component.scss'],
})
export class CommonCardHeaderComponent {
  @Input() public showBackButton: boolean;
  @Input() public defaultBackButtonRoute: string;
  @Input() public showCloseButton: boolean;
  @Input() public onBackButtonClick: () => void = noop;
  @Output() public cardEvent = new EventEmitter<CommonCardEvent>();

  public close(): void {
    this.cardEvent.emit({ event: COMMON_CARD_EVENT_TYPE.CLOSE });
  }
}
