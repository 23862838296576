import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { V1AuthUser } from '@app/models/api/v1/auth/auth.model';

export const userInfoActions = createActionGroup({
  source: 'Auth - User Info',
  events: {
    refetch: emptyProps(),
    start: emptyProps(),
    success: props<{ response: V1AuthUser }>(),
    error: emptyProps(),
  },
});
