import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { POST_V1_AuthHoldingLoginParams, POST_V1_AuthHoldingLoginResponse } from '@app/models/api/v1/auth/auth.model';

export const holdingActions = createActionGroup({
  source: 'Auth - Holding Switch',
  events: {
    start: props<{ params: POST_V1_AuthHoldingLoginParams }>(),
    success: props<{ response: POST_V1_AuthHoldingLoginResponse }>(),
    error: emptyProps(),
  },
});
