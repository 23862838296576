import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { V1AuthLogin, V1AuthMFA } from '@app/models/api/v1/auth/auth.model';

export const mfaSetupActions = createActionGroup({
  source: 'Auth - MFA - Setup',
  events: {
    start: props<{ countryCode: string; phone: string }>(),
    success: props<{ response: V1AuthMFA }>(),
    error: emptyProps(),
  },
});

export const mfaSkipActions = createActionGroup({
  source: 'Auth - MFA - Skip',
  events: {
    start: props<{ isPermament: boolean }>(),
    success: props<{ response: V1AuthLogin }>(),
    error: emptyProps(),
  },
});

export const mfaCodeSubmitActions = createActionGroup({
  source: 'Auth - MFA - Submit Code',
  events: {
    start: props<{ code: string }>(),
    success: props<{ response: V1AuthLogin }>(),
    error: emptyProps(),
  },
});

export const mfaResendCodeActions = createActionGroup({
  source: 'Auth - MFA - Resend Code',
  events: {
    start: emptyProps(),
    success: props<{ response: V1AuthMFA }>(),
    error: emptyProps(),
  },
});
