import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { POST_V1_AuthSetPasswordParams } from '@app/models/api/v1/auth/auth.model';

export const setPasswordActions = createActionGroup({
  source: 'Auth - Set Password',
  events: {
    start: props<{ params: POST_V1_AuthSetPasswordParams }>(),
    success: emptyProps(),
    error: emptyProps(),
    resetForm: emptyProps(),
  },
});
