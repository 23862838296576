import { Component, HostBinding, OnInit } from '@angular/core';
import { fadeInOnEnterAnimation } from 'angular-animations';

@Component({
  selector: 'slnk-shared-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss'],
  animations: [
    fadeInOnEnterAnimation(),
  ]
})
export class NotFoundPageComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
