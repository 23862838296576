import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, exhaustMap, first, of, switchMap } from 'rxjs';

import { V1_AuthService } from '@app/api/v1/auth/v1-auth.service';
import { navigationActions } from '@app/store/actions/navigation.actions';
import { selectQueryParam } from '@app/store/selectors/router.selectors';
import { verifyEmailActions } from '../actions/verify-email.actions';

@Injectable()
export class VerifyEmailEffects {
  public readonly verifyEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(verifyEmailActions.start),
      switchMap(() =>
        this.store.select(selectQueryParam('token')).pipe(
          first(),
          exhaustMap(token =>
            this.v1_AuthService.verifyEmail({ token: token ?? '' }).pipe(
              concatMap(() => [verifyEmailActions.success(), navigationActions.navigateTo({ commands: ['/', 'app', 'dashboard'] })]),
              catchError(() => of(verifyEmailActions.error())),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private v1_AuthService: V1_AuthService,
  ) {}
}
