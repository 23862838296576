import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'slnk-common-card-body',
  templateUrl: './common-card-body.component.html',
  styleUrls: ['./common-card-body.component.scss']
})
export class CommonCardBodyComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
