import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { selectUser } from '@app/auth/store/selectors/user.selectors';
import { V1AuthUser } from '@app/models/api/v1/auth/auth.model';
import { SubscriberComponent } from '../classes/subscriber.component';

/**
 * @deprecated use NgRx auth store instead to get latest auth updates.
 * TODO: Remove this service in upcoming iterations as it's not used anymore and
 * the application is migrated to NgRx store and running smoothly on the new auth layer.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService extends SubscriberComponent {
  /** @deprecated use auth store selectors instead. */
  private userData: V1AuthUser;

  constructor(private store: Store) {
    super();
    /**
     * Temporary solution until all references to `getUserData()` and this service are removed.
     */
    this.subs.push(
      // eslint-disable-next-line @ngrx/no-store-subscription
      this.store.select(selectUser).subscribe(user => {
        if (user) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.userData = user;
        } else {
          this.userData = null;
        }
      }),
    );
  }

  /**
   * @deprecated Use new auth store selectors or `PermissionService` instead. ⚠️
   * Will be removed in upcoming iterations, please remove all references to this method.
   */
  public getUserData(): V1AuthUser {
    return this.userData;
  }
}
