import { HttpHeaders } from '@angular/common/http';

// TODO USE EXPORTED FUNCTION ON EVERY USE CASE han refactor it to skip-toast with
// configuration of which toast at headers value
export const SkipToastInterceptor = 'skip-toast';
export const SkipSuccessToastInterceptor = 'skip-success-toast';

export function getSkipToastInterceptor(): HttpHeaders {
  return new HttpHeaders().set(SkipToastInterceptor, '');
}

export function getSkipSuccessToastInterceptor(): HttpHeaders {
  return new HttpHeaders().set(SkipSuccessToastInterceptor, '');
}
