<nb-layout>
  <nb-layout-column>
    <div class="my-auto py-5">
      <div class="text-center">
        <img
          class="w-100"
          style="max-width: 350px"
          src="../../assets/images/logos/purple/logo_name.png" />
      </div>
      <h3 class="text-center">Sloneek maintenance</h3>
      <p class="text-center">
        Sloneek is currently performing a maintenance, it should not last longer than 15 minutes, please try again later.
      </p>
    </div>
  </nb-layout-column>
</nb-layout>
