import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { V1AuthLogin } from '@app/models/api/v1/auth/auth.model';

export const loginByTokenActions = createActionGroup({
  source: 'Auth - Login By Token',
  events: {
    start: props<{ token: string; redirectUrl?: string }>(),
    success: props<{ response: V1AuthLogin; redirectUrl?: string }>(),
    error: emptyProps(),
  },
});
