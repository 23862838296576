import { Injectable } from '@angular/core';

import { JWT_TOKEN_STORAGE, JWT_REFRESH_TOKEN_STORAGE, ADMIN_LOGGED, TERMINAL_DATA } from '@app/config';
import { V1AuthTerminal } from '@app/models/api/v1/auth/auth.model';

@Injectable()
export class PersistanceService {
  public persistAccessToken(accessToken: string): void {
    localStorage.setItem(JWT_TOKEN_STORAGE, accessToken);
  }

  public persistRefreshToken(refreshToken: string): void {
    localStorage.setItem(JWT_REFRESH_TOKEN_STORAGE, refreshToken);
  }

  public persistAdminLogin(isLogged: boolean): void {
    localStorage.setItem(ADMIN_LOGGED, isLogged.toString());
  }

  public persistTerminal(terminal: V1AuthTerminal): void {
    localStorage.setItem(TERMINAL_DATA, JSON.stringify(terminal));
  }

  public getAccessToken(): string | null {
    try {
      return localStorage.getItem(JWT_TOKEN_STORAGE) ?? null;
    } catch (_) {
      throw new Error('Error getting access token');
    }
  }

  public getRefreshToken(): string | null {
    try {
      return localStorage.getItem(JWT_REFRESH_TOKEN_STORAGE) ?? null;
    } catch (_) {
      throw new Error('Error getting refresh token');
    }
  }

  public getAdminLogin(): boolean {
    try {
      return localStorage.getItem(ADMIN_LOGGED) === 'true';
    } catch (_) {
      throw new Error('Error getting admin login');
    }
  }

  public getTerminal(): V1AuthTerminal | null {
    try {
      const terminal = localStorage.getItem(TERMINAL_DATA);
      if (terminal) {
        return JSON.parse(terminal);
      } else {
        return null;
      }
    } catch (_) {
      throw new Error('Failed to parse terminal');
    }
  }

  public removeAccessToken(): void {
    localStorage.removeItem(JWT_TOKEN_STORAGE);
  }

  public removeRefreshToken(): void {
    localStorage.removeItem(JWT_REFRESH_TOKEN_STORAGE);
  }

  public removeAdminLogin(): void {
    localStorage.removeItem(ADMIN_LOGGED);
  }

  public removeTerminal(): void {
    localStorage.removeItem(TERMINAL_DATA);
  }
}
