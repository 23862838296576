import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';

import { V1_AuthService } from '@app/api/v1/auth/v1-auth.service';
import { passwordSettingsActions } from '../actions/password-settings.actions';

@Injectable()
export class PasswordSettingsEffects {
  public readonly getPasswordSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(passwordSettingsActions.start),
      exhaustMap(({ params }) =>
        this.v1_AuthService.getPasswordSettings(params).pipe(
          map(response => passwordSettingsActions.success({ response: response.data })),
          catchError(() => of(passwordSettingsActions.error())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private v1_AuthService: V1_AuthService,
  ) {}
}
