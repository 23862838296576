import { Injectable } from '@angular/core';

import { NbDateService } from '@nebular/theme';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { filter, map, tap } from 'rxjs';

import { LANGUAGE_LOCAL_STORAGE_KEY } from '@app/config';
import { LANGUAGE_CODE } from '@app/shared/models/localization-lang';
import { LocalizationService } from '@app/shared/services/localization.service';
import { navigationActions } from '../actions/navigation.actions';
import { translateActions } from '../actions/translate.actions';

@Injectable()
export class TranslateEffects {
  public readonly changeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(translateActions.setLanguage),
      tap(action => {
        localStorage.setItem(LANGUAGE_LOCAL_STORAGE_KEY, action.language);
        this.translateService.use(action.language);
        if (action.language === LANGUAGE_CODE.TESTING_LANGUAGE_CODE) {
          this.translateService.setDefaultLang(action.language);
        }
        this.localizationService.registerLocale(action.language);
        this.nebularDateService.setLocale(action.language);
      }),
      filter(({ skipPageReload }) => !skipPageReload),
      map(() => navigationActions.reloadCurrentRoute()),
    ),
  );

  constructor(
    private actions$: Actions,
    private translateService: TranslateService,
    private localizationService: LocalizationService,
    private nebularDateService: NbDateService<unknown>,
  ) {}
}
