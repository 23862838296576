<div class="row no-gutters align-items-center">
  <div
    class="col-auto mr-3"
    *ngIf="showBackButton">
    <a
      class="pointer"
      (click)="onBackButtonClick()"
      [routerLink]="defaultBackButtonRoute ?? null">
      <nb-icon
        icon="arrow-left"
        class="text-primary">
      </nb-icon>
    </a>
  </div>
  <div class="col">
    <ng-content> </ng-content>
  </div>
  <div
    class="col-auto ml-3"
    *ngIf="showCloseButton">
    <a
      class="pointer"
      (click)="close()">
      <i class="fas fa-times text-primary"></i>
    </a>
  </div>
</div>
