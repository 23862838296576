import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import { concatMap, map, switchMap, tap } from 'rxjs';

import { selectUser } from '@app/auth/store/selectors/user.selectors';
import { environment } from '@app/environments/environment';
import { sentryActions } from '../actions/sentry.actions';

@Injectable()
export class SentryEffects implements OnInitEffects {
  public readonly initSentry$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sentryActions.init),
      concatMap(() => {
        Sentry.setTag('appVersion', environment.appVersion);
        return [sentryActions.listenToUserChanges()];
      }),
    ),
  );

  public readonly listenToUserChanges$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sentryActions.listenToUserChanges),
      switchMap(() => this.store.select(selectUser).pipe(map(user => sentryActions.updateUser({ user })))),
    ),
  );

  public readonly updateUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(sentryActions.updateUser),
        tap(({ user }) => {
          if (user) {
            Sentry.setUser({
              email: user.email,
              created: user.created,
              company: user.company.company_name,
              roles: user.roles,
              modules: user.modules,
              application_settings: user.company.application_settings,
              licence: user.company.licence,
            });
          } else {
            Sentry.setUser(null);
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
  ) {}

  public ngrxOnInitEffects(): Action {
    return sentryActions.init();
  }
}
