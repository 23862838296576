import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { V1AuthUser } from '@app/models/api/v1/auth/auth.model';

export const sentryActions = createActionGroup({
  source: 'App - Sentry',
  events: {
    init: emptyProps(),
    listenToUserChanges: emptyProps(),
    updateUser: props<{ user: V1AuthUser }>(),
  },
});
