import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';

import { V1_AuthService } from '@app/api/v1/auth/v1-auth.service';
import { changePasswordActions } from '../actions/change-password.actions';

@Injectable()
export class ChangePasswordEffects {
  public readonly changePassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changePasswordActions.start),
      exhaustMap(({ params }) =>
        this.v1_AuthService.changePassword(params).pipe(
          map(() => changePasswordActions.success()),
          catchError(() => of(changePasswordActions.error())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private v1_AuthService: V1_AuthService,
  ) {}
}
