import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { SkipSuccessToastInterceptor, SkipToastInterceptor } from '@app/helpers/skip-toast';
import { ToastHandlerService } from '@app/shared/services/toast-handler.service';

@Injectable()
export class ToastHandlerInterceptor implements HttpInterceptor {
  constructor(private toast: ToastHandlerService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const hasSkipToast = request.headers.has(SkipToastInterceptor);
    const hasSkipSuccessToast = request.headers.has(SkipSuccessToastInterceptor);
    let headers = request.headers;

    if (hasSkipToast) {
      headers = request.headers.delete(SkipToastInterceptor).delete(SkipSuccessToastInterceptor);
      // Skip toast interceptor completely.
      return next.handle(request.clone({ headers }));
    }

    if (hasSkipSuccessToast) {
      headers = request.headers.delete(SkipSuccessToastInterceptor);
    }

    return next.handle(request.clone({ headers })).pipe(
      map(event => {
        if (event instanceof HttpResponse) {
          if (request.method === 'PUT' || request.method === 'DELETE' || request.method === 'POST' || request.method === 'PATCH') {
            if (request.url.includes('/auth/refresh')) {
              return event;
            } else {
              // 204 responses don't have a body.message, so this would throw an error.
              if (`${event.status}`.startsWith('2') && event.status !== 204 && !hasSkipSuccessToast) {
                this.toast.showSuccessToastsResponse(event.body.message);
              }
            }
          }
        }
        return event;
      }),
      catchError(err => {
        if (!err.error?.token_expired) {
          try {
            this.toast.showErrorToastsResponse(err);
          } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
          }
        }
        return throwError(() => err);
      }),
    );
  }
}
