import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonBackButtonHistoryDirective } from './directives/common-back-button-history.directive';
import { CommonBackButtonHistoryService } from './services/common-back-button-history.service';


@NgModule({
  declarations: [
    CommonBackButtonHistoryDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CommonBackButtonHistoryDirective
  ],
})
export class CommonBackButtonHistoryModule {
  static forRoot(): ModuleWithProviders<CommonBackButtonHistoryModule> {
    return {
      ngModule: CommonBackButtonHistoryModule,
      providers: [CommonBackButtonHistoryService],
    };
  }
}
