import { Directive, OnDestroy } from '@angular/core';

import { Subscription } from 'rxjs';

/**
 * @deprecated use `takeUntilDestroyed`, `takeUntil`, `take` operator for unsubscribing observables
 */
@Directive()
export class SubscriberComponent implements OnDestroy {
  public subs: Subscription[] = [];
  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
