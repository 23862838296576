import { createFeatureSelector, createSelector } from '@ngrx/store';

import { SingleTenantState } from '../reducers/single-tenant.reducer';

export const selectSingleTenantState = createFeatureSelector<SingleTenantState>('singleTenant');

export const selectIsSingleTenantApp = createSelector(selectSingleTenantState, state => state.isSingleTenant);

export const selectSingleTenantConfig = createSelector(selectSingleTenantState, state => state.config);

export const selectIsReadyOrHasSingleTenantConfig = createSelector(
  selectSingleTenantState,
  state => !state.isSingleTenant || (state.isSingleTenant && state.config !== null),
);
