import { createReducer, on } from '@ngrx/store';

import { V1AuthUser } from '@app/models/api/v1/auth/auth.model';
import Nullable from '@app/models/typescript/nullable.model';
import { loginActions } from '../actions/login.actions';
import { logoutActions } from '../actions/logout.actions';
import { initialPersistanceActions } from '../actions/persistance.actions';
import { userInfoActions } from '../actions/user-info.actions';

export type UserState = {
  initialAuthAttempt: boolean;
  isLoadingUserInfo: boolean;
  user: Nullable<V1AuthUser>;
  isAdminLogged: boolean;
};

export const initialUserState: UserState = {
  user: null,
  isLoadingUserInfo: false,
  initialAuthAttempt: false,
  isAdminLogged: false,
};

export const userReducer = createReducer(
  initialUserState,
  on(
    userInfoActions.start,
    (): UserState => ({
      ...initialUserState,
      isLoadingUserInfo: true,
    }),
  ),
  on(
    userInfoActions.success,
    (state, { response }): UserState => ({
      ...state,
      user: response,
      initialAuthAttempt: true,
      isLoadingUserInfo: false,
    }),
  ),
  on(
    loginActions.success,
    (state, { response, asAdmin }): UserState => ({
      ...state,
      user: response.user,
      initialAuthAttempt: true,
      isAdminLogged: asAdmin ?? false,
    }),
  ),
  on(
    initialPersistanceActions.isAdminLoginLoaded,
    (state): UserState => ({
      ...state,
      isAdminLogged: true,
    }),
  ),
  on(
    initialPersistanceActions.tokensFailed,
    userInfoActions.error,
    loginActions.error,
    (state): UserState => ({
      ...state,
      initialAuthAttempt: true,
      isLoadingUserInfo: false,
    }),
  ),
  on(
    logoutActions.logout,
    (): UserState => ({
      ...initialUserState,
      initialAuthAttempt: true,
    }),
  ),
);
