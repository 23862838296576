import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedDialog, sharedDialogAnimation } from '../../../../shared/classes/shared-dialog/shared-dialog';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'slnk-common-pwa-install-dialog',
  templateUrl: './common-pwa-install-dialog.component.html',
  styleUrls: ['./common-pwa-install-dialog.component.scss'],
  animations: [sharedDialogAnimation]
})
export class CommonPwaInstallDialogComponent extends SharedDialog implements OnInit {
  public browser = '';

  @Input() device: 'ios' | 'android' | 'android-add';

  @Output() installButtonClicked = new EventEmitter();

  constructor(protected dialogRef: NbDialogRef<CommonPwaInstallDialogComponent>) {
    super(dialogRef, true);
  }

  ngOnInit(): void {
    this.getBrowser();
  }

  getBrowser() {
    if(navigator.userAgent.indexOf('Safari') !== -1 && navigator.userAgent.indexOf('Chrome') === -1){
      this.browser =  'Safari';
    }else {
      this.browser =  '';
    }
  }
}
