import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { ZendeskService } from '@app/api/v1/module-core/zendesk/zendesk.service';

@NgModule({
  imports: [HttpClientModule],
  providers: [ZendeskService],
})
export class ZendeskModule {}
