import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, map, of, switchMap } from 'rxjs';

import { V1_AuthService } from '@app/api/v1/auth/v1-auth.service';
import { LANGUAGE_CODE } from '@app/shared/models/localization-lang';
import { translateActions } from '@app/store/actions/translate.actions';
import { changeLanguageActions } from '../actions/change-language.actions';
import { userInfoActions } from '../actions/user-info.actions';

@Injectable()
export class ChangeLanguageEffects {
  public readonly changeLanguage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeLanguageActions.start),
      switchMap(({ localeCode }) =>
        localeCode === LANGUAGE_CODE.TESTING_LANGUAGE_CODE
          ? of(translateActions.setLanguage({ language: localeCode }))
          : this.v1_AuthService.changeUserLanguage({ language: localeCode }).pipe(
              map(response => changeLanguageActions.success({ response: response.data, localeCode })),
              catchError(() => of(changeLanguageActions.error())),
            ),
      ),
    ),
  );

  public readonly storeUpdatedUserObject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(changeLanguageActions.success),
      concatMap(({ response, localeCode }) => [
        userInfoActions.success({ response }),
        translateActions.setLanguage({ language: localeCode }),
      ]),
    ),
  );

  constructor(
    private actions$: Actions,
    private v1_AuthService: V1_AuthService,
  ) {}
}
