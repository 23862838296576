import { Data, Params } from '@angular/router';

import { DEFAULT_ROUTER_FEATURENAME, getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const selectRouter = createFeatureSelector<RouterReducerState>(DEFAULT_ROUTER_FEATURENAME);

export const {
  selectCurrentRoute, // select the current route
  selectFragment, // select the current route fragment
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectRouteDataParam, // factory function to select a route data param
  selectUrl, // select the current url
  selectTitle, // select the title if available
} = getRouterSelectors();

export const selectRouteNestedParams = createSelector(selectRouter, router => {
  let currentRoute = router?.state?.root;
  let params: Params = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    params = {
      ...params,
      ...currentRoute.params,
    };
  }
  return params;
});

export const selectRouteNestedData = createSelector(selectRouter, router => {
  let currentRoute = router?.state?.root;
  let data: Data = {};
  while (currentRoute?.firstChild) {
    currentRoute = currentRoute.firstChild;
    data = {
      ...data,
      ...currentRoute.data,
    };
  }
  return data;
});
