/* eslint-disable @typescript-eslint/no-explicit-any */

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { NbGlobalLogicalPosition, NbToastrService } from '@nebular/theme';
import { NbToastrConfig } from '@nebular/theme/components/toastr/toastr-config';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ToastHandlerService {
  private toastConfig: Partial<NbToastrConfig> = {
    limit: 3,
    position: NbGlobalLogicalPosition.BOTTOM_START,
    preventDuplicates: true,
    destroyByClick: true,
  };
  private toastConfigNotificationAssets: Partial<NbToastrConfig> = {
    limit: 3,
    position: NbGlobalLogicalPosition.TOP_END,
    preventDuplicates: true,
    icon: {
      icon: 'bell',
      pack: 'regular',
    },
  };

  constructor(
    private toastrService: NbToastrService,
    private translateService: TranslateService,
  ) {}

  public showErrorToast(title: string, message?: string, customOptions?: Partial<NbToastrConfig>, showDefaultMessage = true): void {
    this.toastrService.danger(
      message ? message : showDefaultMessage ? 'Error' : null,
      title,
      customOptions ? Object.assign(this.toastConfig, customOptions) : this.toastConfig,
    );
  }

  /**
   * show main response error and all validation errors
   * @param error http error
   */
  public showErrorToastsResponse(error: HttpErrorResponse, subtitle?: string): any {
    let errorsObject = {};
    if (error && error.error) {
      this.toastrService.danger(subtitle, error.error.message, this.toastConfig);
    } else {
      this.toastrService.danger(subtitle, 'Error', this.toastConfig);
    }

    if (error && error.error && error.error.errors) {
      errorsObject = error.error.errors;
      Object.keys(errorsObject).forEach(key => {
        this.toastrService.danger(subtitle, errorsObject[key], this.toastConfig);
      });
    }
    return errorsObject;
  }

  public showSuccessToastsResponse(message: any, subtitle?: string): void {
    if (message) {
      this.toastrService.success(subtitle, message, this.toastConfig);
    } else {
      this.toastrService.success(subtitle, this.translateService.instant('fe.globals.toasts.success.saved'), this.toastConfig);
    }
  }

  public showValidationErrors(error: HttpErrorResponse): any {
    let errorsObject = {};
    if (error && error.error && error.error.errors) {
      errorsObject = error.error.errors;
      Object.keys(error.error.errors).forEach(key => {
        this.toastrService.danger(error.error.errors[key], 'text', this.toastConfig);
      });
    } else {
      this.toastrService.danger(this.translateService.instant('fe.globals.toasts.errors.problem_width_server'), 'text', this.toastConfig);
    }
    return errorsObject;
  }

  public showSuccessToast(message: string, title?: string): void {
    this.toastrService.success(message, title ? title : this.translateService.instant('fe.globals.toasts.success.title'), this.toastConfig);
  }

  public showNotificationToast(message: string, title: string): void {
    this.toastrService.warning(message, title, this.toastConfigNotificationAssets);
  }
}
