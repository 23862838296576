import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { SingleTenantConfig } from '../reducers/single-tenant.reducer';

export const singleTenantActions = createActionGroup({
  source: 'App - Single tenant',
  events: {
    initialize: emptyProps(),
    loadConfig: emptyProps(),
    configLoaded: props<{ config: SingleTenantConfig }>(),
    missingConfig: emptyProps(),
  },
});
