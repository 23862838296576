import { createActionGroup, props } from '@ngrx/store';

import { LANGUAGE_CODE } from '@app/shared/models/localization-lang';

export const translateActions = createActionGroup({
  source: 'App - Translate',
  events: {
    setLanguage: props<{ language: LANGUAGE_CODE; skipPageReload?: boolean }>(),
  },
});
