import { Component, Input, ViewChild } from '@angular/core';

import { displayPageButtonsOnRightAnimation, displayPageTitleAnimation } from '@app/shared/animations/displayPageAnimations';
import { CommonBackButtonHistoryDirective } from '../../../common-back-button-history/directives/common-back-button-history.directive';

@Component({
  selector: 'slnk-common-card-page-header',
  templateUrl: './common-card-page-header.component.html',
  styleUrls: ['./common-card-page-header.component.scss'],
  animations: [
    displayPageTitleAnimation,
    displayPageButtonsOnRightAnimation,
  ],
})
export class CommonCardPageHeaderComponent {
  @Input() public transparent: boolean;
  @Input() public backButtonId: string;
  @Input() public backLink: string;
  @Input() public actionColumnClasses: string;
  @Input() public removeHeaderPadding: boolean;
  @ViewChild(CommonBackButtonHistoryDirective) public backButtonHistoryDirective: CommonBackButtonHistoryDirective;

  public navigateToPreviousRoute(): void {
    this.backButtonHistoryDirective?.navigateToLastRoute();
  }

}
