import { map, OperatorFunction } from 'rxjs';

import APIArrayResponse from '../../models/api/array-response.model';
import APIObjectResponse from '../../models/api/object-response.model';

/**
 * Map to original type which was originally wrapped in
 * APIObjectResponse or APIArrayResponse, in case it is
 * completely different type, return it as fallback.
 */
type ExtractAPIDataType<T> =
  T extends APIObjectResponse<infer ObjectType> ? ObjectType : T extends APIArrayResponse<infer ArrayType> ? ArrayType : T;

/**
 * RxJs operator which helps to map API responses automatically to the data property of the response.
 * So you don't have to do `map((res: any) => res.data)` every time you get a response from the API.
 * To be able to use this operator, your API needs to return `APIObjectResponse<T>` or `APIArrayResponse<T>`.
 */
function mapToData<T extends object>(): OperatorFunction<T, ExtractAPIDataType<T>> {
  return map<T, ExtractAPIDataType<T>>(source => ('data' in source ? source.data : source) as ExtractAPIDataType<T>);
}

export default mapToData;
