import { createReducer, on } from '@ngrx/store';

import { V1_AUTH_LOGIN_TYPE } from '@app/models/api/v1/auth/auth.enums';
import { V1AuthMFA } from '@app/models/api/v1/auth/auth.model';
import Nullable from '@app/models/typescript/nullable.model';
import { loginActions } from '../actions/login.actions';
import { mfaCodeSubmitActions, mfaResendCodeActions, mfaSetupActions, mfaSkipActions } from '../actions/mfa.actions';

export type MFAState = {
  data: Nullable<V1AuthMFA>;
  loginType: Nullable<V1_AUTH_LOGIN_TYPE>;
  isSubmittingCode: boolean;
  isResendingCode: boolean;
  isSkippingMFA: boolean;
  isSettingUpMFA: boolean;
};

export const initialMFAState: MFAState = {
  data: null,
  loginType: null,
  isSubmittingCode: false,
  isResendingCode: false,
  isSkippingMFA: false,
  isSettingUpMFA: false,
};

export const mfaReducer = createReducer(
  initialMFAState,
  on(
    loginActions.useMFA,
    loginActions.setupMFA,
    (state, { response }): MFAState => ({
      ...state,
      loginType: response.login_type,
      data: {
        attempts: response.attempts,
        token: response.token,
        anonymized_phone_number: response.anonymized_phone_number,
        valid_until: response.valid_until,
      },
    }),
  ),
  on(
    mfaCodeSubmitActions.start,
    (state): MFAState => ({
      ...state,
      isSubmittingCode: true,
    }),
  ),
  on(
    mfaCodeSubmitActions.error,
    mfaCodeSubmitActions.success,
    (state): MFAState => ({
      ...state,
      isSubmittingCode: false,
    }),
  ),
  on(
    mfaSkipActions.start,
    (state): MFAState => ({
      ...state,
      isSkippingMFA: true,
    }),
  ),
  on(
    mfaSkipActions.error,
    mfaSkipActions.success,
    (state): MFAState => ({
      ...state,
      isSkippingMFA: false,
    }),
  ),
  on(
    mfaResendCodeActions.start,
    (state): MFAState => ({
      ...state,
      isResendingCode: true,
    }),
  ),
  on(
    mfaResendCodeActions.success,
    (state, { response }): MFAState => ({
      ...state,
      isResendingCode: false,
      data: {
        attempts: response.attempts,
        token: response.token,
        anonymized_phone_number: response.anonymized_phone_number,
        valid_until: response.valid_until,
      },
    }),
  ),
  on(
    mfaResendCodeActions.error,
    (state): MFAState => ({
      ...state,
      isResendingCode: false,
    }),
  ),
  on(
    mfaSetupActions.start,
    (state): MFAState => ({
      ...state,
      isSettingUpMFA: true,
    }),
  ),
  on(
    mfaSetupActions.success,
    (state, { response }): MFAState => ({
      ...state,
      isSettingUpMFA: false,
      data: {
        attempts: response.attempts,
        token: response.token,
        anonymized_phone_number: response.anonymized_phone_number,
        valid_until: response.valid_until,
      },
    }),
  ),
  on(
    mfaSetupActions.error,
    (state): MFAState => ({
      ...state,
      isSettingUpMFA: false,
    }),
  ),
);
