import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';

import { V1_AuthService } from '@app/api/v1/auth/v1-auth.service';
import { resetPasswordActions } from '../actions/reset-password.actions';

@Injectable()
export class ResetPasswordEffects {
  public readonly resetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(resetPasswordActions.start),
      exhaustMap(({ email }) =>
        this.v1_AuthService.forgotPassword({ email }).pipe(
          map(() => resetPasswordActions.success()),
          catchError(() => of(resetPasswordActions.error())),
        ),
      ),
    ),
  );

  constructor(
    private actions$: Actions,
    private v1_AuthService: V1_AuthService,
  ) {}
}
