import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { CommonBackButtonHistoryDirective } from '../directives/common-back-button-history.directive';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';

@Injectable()
export class CommonBackButtonHistoryService {
  private rememberingRoutesSub: Subscription;
  rememberedRoutes: string[] = [];
  // isBackButtonClicked: boolean;
  isBackButtonRendered: boolean;
  renderedSecondaryBackButtons: CommonBackButtonHistoryDirective[] = [];

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  public subscribeToRouterChanges() {
    this.resetRememberedRoutes();
    // this.rememberedRoutes.push(this.route.snapshot.url);
    // console.log('URL', this.route.snapshot.url);
    // TODO HANDLE
    // subscribe to route changes, push every new route tou rememberedRoutes
    this.rememberingRoutesSub = this.router.events.pipe(filter(item => item instanceof NavigationEnd)).subscribe(res => {
      if (res instanceof NavigationEnd) {
        // this.router.getCurrentNavigation()?.extras?.replaceUrl
        // only if user do not clicked on back button save route and navigation extras do not have replaceUrl true
        // !this.isBackButtonClicked &&
        // TODO MAYBE check if replaceUrlIs set and then replace last url
        if (this.isBackButtonRendered || this.router.getCurrentNavigation()?.extras?.replaceUrl) {
          this.replaceLastRememberedRoute(res.url);
        } else {
          // if (/*!this.isBackButtonRendered && */!this.router.getCurrentNavigation()?.extras?.replaceUrl) {
          if (this.rememberedRoutes.length > 0) {
            // split path url from queryParameter
            const urlPathWithoutQuery = res.url.split('?')[0];
            // split path url from fragments
            const urlPath = urlPathWithoutQuery.split('#')[0];
            const lastRememberedUrlPath = this.rememberedRoutes[this.rememberedRoutes.length - 1].split('?')[0];
            // if current and last url path is same, just rewrite last url
            if (urlPath === lastRememberedUrlPath) {
              this.replaceLastRememberedRoute(res.url);
            } else {
              // if current and last url is different, add url to rememberedRoutes
              this.rememberedRoutes.push(res.url);
            }
          } else {
            // if its first route to remember
            this.rememberedRoutes.push(res.url);
          }
          // }
        }
      }
    });
  }

  private replaceLastRememberedRoute(url: string) {
    if (this.rememberedRoutes.length > 0) {
      this.rememberedRoutes[this.rememberedRoutes.length - 1] = url;
    } else {
      this.rememberedRoutes.push(url);
    }
  }

  public unsubscribeToRouterChanges() {
    if (this.rememberingRoutesSub) {
      this.rememberingRoutesSub.unsubscribe();
      this.rememberingRoutesSub = null;
    }
    this.resetRememberedRoutes();
  }

  /**
   * create new array from current rememberedRoutes
   */
  public getCurrentRoutes() {
    return Array.from(this.rememberedRoutes);
  }

  public resetRememberedRoutes() {
    this.rememberedRoutes = [];
    this.renderedSecondaryBackButtons = [];
  }
}
