import { Component, ContentChild, ElementRef, Input, OnInit } from '@angular/core';

import { CommonCardBodyComponent } from '../common-card-body/common-card-body.component';
import { CommonCardFooterComponent } from '../common-card-footer/common-card-footer.component';
import { CommonCardHeaderComponent } from '../common-card-header/common-card-header.component';

@Component({
  selector: 'slnk-common-card',
  templateUrl: './common-card.component.html',
  styleUrls: ['./common-card.component.scss'],
})
export class CommonCardComponent implements OnInit {
  public isBodyDisplayed = false;
  public isFooterDisplayed = false;
  public isHeaderDisplayed = false;
  @Input() public showSpinner: boolean;
  @Input() public fullHeight: boolean;
  @Input() public needSwipe: boolean;
  @Input() public removeBodyPadding: boolean;
  @Input() public smallHeaderPadding: boolean;
  @Input() public removeHeaderPadding: boolean;
  @Input() public styleClass: string;
  @Input() public cardColor:
    | 'basic'
    | 'dark'
    | 'dark-transparent'
    | 'light-green'
    | 'light-blue'
    | 'light-yellow'
    | 'light-red'
    | 'basic-transparent'
    | 'grey' = 'basic';
  @ContentChild(CommonCardBodyComponent, { static: true, read: ElementRef }) public cardBody: ElementRef;
  @ContentChild(CommonCardFooterComponent, { static: true, read: ElementRef }) public cardFooter: ElementRef;
  @ContentChild(CommonCardHeaderComponent, { static: true, read: ElementRef }) public cardHeader: ElementRef;

  public ngOnInit(): void {
    if (this.cardBody && this.cardBody.nativeElement.innerHTML) {
      this.isBodyDisplayed = true;
    }
    if (this.cardFooter && this.cardFooter.nativeElement.innerHTML) {
      this.isFooterDisplayed = true;
    }
    if (this.cardHeader && this.cardHeader.nativeElement.innerHTML) {
      this.isHeaderDisplayed = true;
    }
  }
}
