import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, concatMap, exhaustMap, map, of } from 'rxjs';

import { V1_AuthService } from '@app/api/v1/auth/v1-auth.service';
import { V1_BathService } from '@app/api/v1/bath/v1-bath.service';
import { navigationActions } from '@app/store/actions/navigation.actions';
import { adminLoginAsOwnerActions, adminLoginAsUserActions, adminLogoutActions } from '../actions/admin.actions';
import { loginActions } from '../actions/login.actions';
import { persistanceActions } from '../actions/persistance.actions';

@Injectable()
export class AdminEffects {
  public readonly loginAsCompanyOwner$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminLoginAsOwnerActions.start),
      exhaustMap(({ params }) =>
        this.v1_BathService.loginAsCompanyOwner(params).pipe(
          map(response => adminLoginAsOwnerActions.success({ response })),
          catchError(() => of(adminLoginAsOwnerActions.error())),
        ),
      ),
    ),
  );

  public readonly loginAsUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminLoginAsUserActions.start),
      exhaustMap(({ params }) =>
        this.v1_BathService.loginAsUser(params).pipe(
          map(response => adminLoginAsUserActions.success({ response })),
          catchError(() => of(adminLoginAsUserActions.error())),
        ),
      ),
    ),
  );

  public readonly handleLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminLoginAsOwnerActions.success, adminLoginAsUserActions.success),
      map(({ response: { data } }) => loginActions.success({ response: data, asAdmin: true })),
    ),
  );

  public readonly logoutAsAdmin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminLogoutActions.start),
      exhaustMap(() =>
        this.v1_AuthService.logoutHostUser().pipe(
          map(response => adminLogoutActions.success({ response })),
          catchError(() => of(adminLogoutActions.error())),
        ),
      ),
    ),
  );

  public readonly handleLogoutSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminLogoutActions.success),
      concatMap(({ response: { data } }) => [
        loginActions.success({ response: data, skipNavigation: true }),
        persistanceActions.removeAdminLogin(),
        navigationActions.navigateTo({ commands: ['/', 'admin', 'companies', 'users'] }),
      ]),
    ),
  );

  constructor(
    private actions$: Actions,
    private v1_BathService: V1_BathService,
    private v1_AuthService: V1_AuthService,
  ) {}
}
