import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';

import { V1_AuthService } from '@app/api/v1/auth/v1-auth.service';
import { navigationActions } from '@app/store/actions/navigation.actions';
import { loginActions } from '../actions/login.actions';
import { persistanceActions } from '../actions/persistance.actions';
import { terminalActions, terminalLoginActions } from '../actions/terminal.actions';

@Injectable()
export class TerminalEffects {
  public readonly terminalLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(terminalLoginActions.start),
      exhaustMap(({ params }) =>
        this.v1_AuthService.terminalLogin(params).pipe(
          map(response => terminalLoginActions.success({ response: response.data })),
          catchError((response: HttpErrorResponse) =>
            of(terminalLoginActions.error({ validationErrors: response.error.errors, errorMessage: response.error.message })),
          ),
        ),
      ),
    ),
  );

  public readonly saveTerminal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(terminalLoginActions.success),
      map(({ response }) => terminalActions.save({ terminal: response.terminal })),
    ),
  );

  public readonly persistTerminal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(terminalActions.save),
      map(({ terminal }) => persistanceActions.persistTerminal({ terminal })),
    ),
  );

  public readonly removeTerminal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(terminalActions.remove),
      map(() => persistanceActions.removeTerminal()),
    ),
  );

  public readonly storeUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(terminalLoginActions.success),
      map(({ response }) => loginActions.success({ response })),
    ),
  );

  public readonly redirectToCardNumberInput$ = createEffect(() =>
    this.actions$.pipe(
      ofType(terminalLoginActions.success),
      map(() => navigationActions.navigateTo({ commands: ['/', 'terminal', 'card', 'login'] })),
    ),
  );

  constructor(
    private actions$: Actions,
    private v1_AuthService: V1_AuthService,
  ) {}
}
