import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@app/environments/environment';
import mapToData from '@app/helpers/rxjs/map-to-data.helper';
import { getSkipToastInterceptor } from '@app/helpers/skip-toast';

@Injectable()
export class ZendeskService {
  constructor(private http: HttpClient) {}
  public getZendeskUserToken(): Observable<string> {
    return this.http
      .get<{ token: string }>(`${environment.backendUrl}v1/module-core/zendesk-token`, { headers: getSkipToastInterceptor() })
      .pipe(
        mapToData(),
        map(res => res.token),
      );
  }
}
