import { createReducer, on } from '@ngrx/store';

import { verifyEmailActions } from '../actions/verify-email.actions';

export type VerifyEmailState = {
  isVerifyingEmail: boolean;
};

export const initialVerifyEmailState: VerifyEmailState = {
  isVerifyingEmail: false,
};

export const verifyEmailReducer = createReducer(
  initialVerifyEmailState,
  on(
    verifyEmailActions.start,
    (state): VerifyEmailState => ({
      ...state,
      isVerifyingEmail: true,
    }),
  ),
  on(
    verifyEmailActions.success,
    verifyEmailActions.error,
    (state): VerifyEmailState => ({
      ...state,
      isVerifyingEmail: false,
    }),
  ),
);
