import { AfterViewInit, Component, EventEmitter, HostBinding, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SharedDialog, sharedDialogAnimation } from '../../../../shared/classes/shared-dialog/shared-dialog';
import { NbDialogRef } from '@nebular/theme';
import { interval } from 'rxjs';

@Component({
  selector: 'slnk-common-app-update-available-dialog',
  templateUrl: './common-pwa-update-available-dialog.component.html',
  styleUrls: ['./common-pwa-update-available-dialog.component.scss'],
  animations: [sharedDialogAnimation]
})
export class CommonPwaUpdateAvailableDialogComponent extends SharedDialog implements OnInit, AfterViewInit {
  public refreshTimer = 0;
  public isAutoRefreshEnabledLocalStorage = false;
  public showAutoRefreshProgress = false;
  public automaticUpdateAppLocalStorageKey = 'slnk-auto-update-app';
  @Input() showAutoRefreshDialogContent: boolean;
  @Output() updateAppSubmitted = new EventEmitter();
  @ViewChild('reload', {static: false}) reload: any;

  constructor(protected dialogRef: NbDialogRef<CommonPwaUpdateAvailableDialogComponent>) {
    super(dialogRef, true);
  }

  @HostBinding('class.small_update_card')
  get isSmallDialog() {
    return !this.showAutoRefreshDialogContent;
  }

  ngOnInit(): void {
    console.log('DIALOG UPDATE');
    this.isAutoRefreshEnabledLocalStorage = localStorage.getItem(this.automaticUpdateAppLocalStorageKey) === 'true';
    this.showAutoRefreshProgress = this.isAutoRefreshEnabledLocalStorage;
  }

  public reloadPage() {
    this.updateAppSubmitted.emit();
  }

  ngAfterViewInit(): void {
    const sub = interval(1000).subscribe(res => {
      this.refreshTimer += 20;
      if (this.refreshTimer >= 100) {
        if (this.showAutoRefreshDialogContent) {
          this.reloadPage();
        } else {
          if (this.isAutoRefreshEnabledLocalStorage && this.showAutoRefreshProgress) {
            this.reloadPage();
          }
        }
        sub.unsubscribe();
      }
    });
  }

  public autoRefreshCheckedChange(event) {
    localStorage.setItem(this.automaticUpdateAppLocalStorageKey, `${this.isAutoRefreshEnabledLocalStorage}`);
  }
}
