import { createReducer, on } from '@ngrx/store';

import { changePasswordActions } from '../actions/change-password.actions';

export type ChangePasswordState = {
  isChangingPassword: boolean;
  shouldResetForm: boolean;
  sectionVisible: boolean;
};

export const initialChangePasswordState: ChangePasswordState = {
  isChangingPassword: false,
  shouldResetForm: false,
  sectionVisible: false,
};

export const changePasswordReducer = createReducer(
  initialChangePasswordState,
  on(
    changePasswordActions.start,
    (state): ChangePasswordState => ({
      ...state,
      isChangingPassword: true,
    }),
  ),
  on(
    changePasswordActions.error,
    (state): ChangePasswordState => ({
      ...state,
      isChangingPassword: false,
    }),
  ),
  on(
    changePasswordActions.success,
    (state): ChangePasswordState => ({
      ...state,
      isChangingPassword: false,
      shouldResetForm: true,
    }),
  ),
  on(
    changePasswordActions.resetForm,
    (state): ChangePasswordState => ({
      ...state,
      shouldResetForm: false,
    }),
  ),
  on(
    changePasswordActions.toggleSection,
    (state): ChangePasswordState => ({
      ...state,
      sectionVisible: !state.sectionVisible,
    }),
  ),
);
