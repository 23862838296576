import { createSelector } from '@ngrx/store';

import { selectAuthState } from '../auth.reducers';

export const selectLoginState = createSelector(selectAuthState, state => state.login);

export const selectIsLoggingIn = createSelector(selectLoginState, state => state.isLoggingIn);

export const selectLoginValidationErrors = createSelector(selectLoginState, state => state.validationErrors);

export const selectChangedLanguage = createSelector(selectLoginState, state => state.changedLanguageTo);
