/* eslint-disable no-console */
/* eslint-disable no-async-promise-executor */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { LOCATION_INITIALIZED } from '@angular/common';
import { Injector } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { delay, mergeMap } from 'rxjs/operators';

import { LANGUAGE_CODE } from '@app/shared/models/localization-lang';
import { LocalizationService } from './services/localization.service';
import { environment } from '../../environments/environment';
import { LOCAL_STORAGE_SAFARI_UPDATE_KEY } from '../common-modules/common-pwa/services/common-pwa-update.service';
import { INITIAL_LANGUAGE, LANGUAGE_LOCAL_STORAGE_KEY } from '../config';

export function appInitializerFactory(translate: TranslateService, localizationService: LocalizationService, injector: Injector) {
  return () =>
    new Promise<any>(async (resolve: any) => {
      const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
      locationInitialized.then(() => {
        const defaultLanguage = INITIAL_LANGUAGE;
        let langToSet = localStorage.getItem(LANGUAGE_LOCAL_STORAGE_KEY);
        if (!langToSet) {
          langToSet = defaultLanguage;
        }
        localizationService.registerLocale(langToSet as LANGUAGE_CODE);
        localizationService
          .getAvailableLanguagesWithoutTesting()
          .toPromise()
          .then(res => {
            translate.addLangs(res.map(item => item.code));
          });
        // SAFARI PWA UPDATE FIX
        // TODO find different solution, after safari browser pwa updated first request failed
        const wasUpdated = localStorage.getItem(LOCAL_STORAGE_SAFARI_UPDATE_KEY);
        of({})
          .pipe(
            // tap(() => console.log('wwwwww')),
            delay(wasUpdated && wasUpdated === 'true' ? 100 : 0),
            // tap(() => console.log('wwwwww')),
            mergeMap(() => {
              if (environment.production) {
                translate.setDefaultLang(defaultLanguage);
              }
              return translate.use(langToSet);
            }),
          )
          .subscribe({
            next: () => console.info(`[INITIALIZER] Successfully initialized '${langToSet}' language.'`),
            error: () => {
              console.error(`[INITIALIZER] Problem with '${langToSet}' language initialization.'`);
              resolve(null);
            },
            complete: () => {
              console.log('[INITIALIZER] App language loaded.');
              const uA = navigator.userAgent;
              const vendor = navigator.vendor;
              if (/Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA)) {
                localStorage.setItem(LOCAL_STORAGE_SAFARI_UPDATE_KEY, 'false');
              }
              resolve(null);
            },
          });
      });
    });
}
