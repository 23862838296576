import { createReducer, on } from '@ngrx/store';

import { V1AuthTerminal } from '@app/models/api/v1/auth/auth.model';
import APIValidationError from '@app/models/api/validation-error.model';
import Nullable from '@app/models/typescript/nullable.model';
import { initialPersistanceActions } from '../actions/persistance.actions';
import { terminalActions, terminalLoginActions } from '../actions/terminal.actions';

export type TerminalState = {
  isLoggingIntoTerminal: boolean;
  errorMessage: Nullable<string>;
  validationErrors: Nullable<APIValidationError>;
  terminal: Nullable<V1AuthTerminal>;
};

export const initialTerminalState: TerminalState = {
  isLoggingIntoTerminal: false,
  errorMessage: null,
  validationErrors: null,
  terminal: null,
};

export const terminalReducer = createReducer(
  initialTerminalState,
  on(
    terminalLoginActions.start,
    (state): TerminalState => ({
      ...state,
      isLoggingIntoTerminal: true,
    }),
  ),
  on(
    terminalLoginActions.error,
    (state, { validationErrors, errorMessage }): TerminalState => ({
      ...state,
      isLoggingIntoTerminal: false,
      validationErrors,
      errorMessage,
    }),
  ),
  on(
    terminalLoginActions.success,
    (state): TerminalState => ({
      ...state,
      isLoggingIntoTerminal: false,
    }),
  ),
  on(
    initialPersistanceActions.loadTerminalLoaded,
    terminalActions.save,
    (state, { terminal }): TerminalState => ({
      ...state,
      terminal,
    }),
  ),
  on(
    terminalActions.remove,
    (state): TerminalState => ({
      ...state,
      terminal: null,
    }),
  ),
);
