import { createReducer, on } from '@ngrx/store';

import { environment } from '@app/environments/environment';
import Nullable from '@app/models/typescript/nullable.model';
import { singleTenantActions } from '../actions/single-tenant.actions';

export type SingleTenantConfig = {
  isRegularLoginDisabled: boolean;
  isGoogleLoginDisabled: boolean;
  isAzureLoginDisabled: boolean;
  isOktaLoginDisabled: boolean;
  atsPortalURL: string;
  backendURL: string;
};

export type SingleTenantState = {
  isSingleTenant: boolean;
  config: Nullable<SingleTenantConfig>;
};

export const initialSingleTenantState: SingleTenantState = {
  isSingleTenant: environment.isSingleTenantApp,
  config: null,
};

export const singleTenantReducer = createReducer(
  initialSingleTenantState,
  on(
    singleTenantActions.configLoaded,
    (state, { config }): SingleTenantState => ({
      ...state,
      config,
    }),
  ),
);
