import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { V1AuthLogin } from '@app/models/api/v1/auth/auth.model';

export const refreshTokenActions = createActionGroup({
  source: 'Auth - Refresh Token',
  events: {
    success: props<{ response: V1AuthLogin }>(),
    error: emptyProps(),
  },
});
