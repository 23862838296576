import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';

import { V1_AuthService } from '@app/api/v1/auth/v1-auth.service';
import { navigationActions } from '@app/store/actions/navigation.actions';
import { holdingActions } from '../actions/holding.actions';
import { loginActions } from '../actions/login.actions';

@Injectable()
export class HoldingEffects {
  public readonly changeCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(holdingActions.start),
      exhaustMap(({ params }) =>
        this.v1_AuthService.holdingLogin(params).pipe(
          map(response => holdingActions.success({ response })),
          catchError(() => of(holdingActions.error())),
        ),
      ),
    ),
  );

  public readonly handleLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(holdingActions.success),
      map(({ response: { data } }) => loginActions.success({ response: data, skipNavigation: true })),
    ),
  );

  public readonly reloadCurrentRouteOnSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(holdingActions.success),
      map(() => navigationActions.reloadCurrentRoute()),
    ),
  );

  constructor(
    private actions$: Actions,
    private v1_AuthService: V1_AuthService,
  ) {}
}
