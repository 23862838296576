import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { PUT_V1_AuthChangePasswordParams } from '@app/models/api/v1/auth/auth.model';

export const changePasswordActions = createActionGroup({
  source: 'Auth - Change Password',
  events: {
    start: props<{ params: PUT_V1_AuthChangePasswordParams }>(),
    success: emptyProps(),
    error: emptyProps(),
    resetForm: emptyProps(),
    toggleSection: emptyProps(),
  },
});
