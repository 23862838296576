import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { BehaviorSubject, interval, Observable } from 'rxjs';
import { filter } from 'rxjs/internal/operators/filter';
import { debounceTime, tap } from 'rxjs/operators';

export const LOCAL_STORAGE_SAFARI_UPDATE_KEY = 'wasSafariUpdated';

@Injectable({
  providedIn: 'root',
})
export class CommonPwaUpdateService {
  private isUpdateAvailableSubject = new BehaviorSubject(false);

  constructor(
    private readonly swUpdate: SwUpdate,
    private router: Router,
  ) {
    // eslint-disable-next-line no-console
    console.log('[PWA UPDATE SERVICE ENABLED]', this.swUpdate.isEnabled);
  }

  public get isUpdateAvailable(): Observable<boolean> {
    return this.isUpdateAvailableSubject.asObservable();
  }

  public initAppUpdateCheck(): void {
    if (this.swUpdate.isEnabled) {
      interval(60000)
        .pipe(
          tap(() => {
            // console.log('[UPDATE SERVICE] CHECK FOR UPDATE');
          }),
        )
        .subscribe(() =>
          this.swUpdate
            .checkForUpdate()
            .then(() => {
              // eslint-disable-next-line no-console
              console.log('[UPDATE SERVICE] CHECK FOR UPDATE UPDATE');
            })
            .catch(err => {
              // eslint-disable-next-line no-console
              console.log('[UPDATE SERVICE] UPDATE CHECK ERROR');
              // eslint-disable-next-line no-console
              console.log(err);
            }),
        );
      // CHECK IF UPDATE IS AVAILABLE
      this.swUpdate.versionUpdates.subscribe(event => {
        // eslint-disable-next-line no-console
        console.log('[UPDATE SERVICE] UPDATE AVAILABLE');
        // eslint-disable-next-line no-console
        console.log(event.type);
        // eslint-disable-next-line no-console
        console.log('current version is', event);
        // eslint-disable-next-line no-console
        console.log('available version is', event);
        // show alert when version is ready
        if (event.type === 'VERSION_READY') {
          this.showAppUpdateAlert();
        }
      });
    }
  }

  private updateAvailable(): void {
    this.isUpdateAvailableSubject.next(true);
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        debounceTime(100),
      )
      .subscribe(() => {
        this.doAppUpdate();
      });
  }

  private showAppUpdateAlert(): void {
    if (this.swUpdate.isEnabled) {
      this.updateAvailable();
    }
  }

  public doAppUpdate(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.activateUpdate().then(() => {
        // Hotfix: CHECK SAFARI BROWSER
        // TODO find different solution, after safari browser pwa updated first request failed
        const uA = navigator.userAgent;
        const vendor = navigator.vendor;
        if (/Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA)) {
          localStorage.setItem(LOCAL_STORAGE_SAFARI_UPDATE_KEY, 'true');
        }
        document.location.reload();
      });
    }
  }
}
