import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, Injector, isDevMode, NgModule } from '@angular/core';
import { BrowserModule, HammerModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';

import 'ag-grid-enterprise';
import { NbMomentDateModule } from '@nebular/moment';
import {
  NbChatModule,
  NbDatepickerModule,
  NbDialogModule,
  NbInputModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbThemeModule,
  NbTimepickerModule,
  NbToastrModule,
} from '@nebular/theme';
import { NgMapsGoogleModule } from '@ng-maps/google';
import { LetDirective, PushPipe } from '@ngrx/component';
import { EffectsModule } from '@ngrx/effects';
import { RouterState, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular-ivy';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MarkdownModule } from 'ngx-markdown';

import { ZendeskModule } from '@app/api/v1/module-core/zendesk/zendesk.module';
import { CommonBackButtonHistoryModule } from '@app/common-modules/common-back-button-history/common-backbutton-history.module';
import { CommonLoadingModule } from '@app/common-modules/common-loading/common-loading.module';
import { CommonPwaModule } from '@app/common-modules/common-pwa/common-pwa.module';
import { environment } from '@app/environments/environment';
import { appInitializerFactory } from '@app/shared/appInitializerFactory';
import { SLONEEK_THEME } from '@app/shared/enums/sloneek-theme';
import { LocaleProvider } from '@app/shared/services/custom-locale-provider';
import { CustomTranslateLoader } from '@app/shared/services/custom-translate-loader.service';
import { LocalizationService } from '@app/shared/services/localization.service';
import { appEffects } from '@app/store/app.effects';
import { appReducers } from '@app/store/app.reducers';
import { AuthPageUnderConstructionComponentComponent } from '@app/under-construction/auth-page-under-construction-component.component';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthCoreModule } from './auth/auth-core.module';

export function createTranslateLoader(http: HttpClient): CustomTranslateLoader {
  return new CustomTranslateLoader(http);
}

@NgModule({
  declarations: [AppComponent, AuthPageUnderConstructionComponentComponent],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    AuthCoreModule,
    BrowserAnimationsModule,
    HttpClientModule,
    // OTHER GOOGLE ANALYTICS MODULES IMPORTED IN productionModulesOnly variable
    GoogleTagManagerModule.forRoot({
      id: environment.googleTagManager,
    }),
    // NgMapsCoreModule,
    NgMapsGoogleModule.forRoot({
      libraries: ['places'],
      apiKey: environment.googleMapsApiKey,
    }),
    NbThemeModule.forRoot({ name: SLONEEK_THEME.LIGHT }),
    NbLayoutModule,
    TranslateModule.forRoot({
      extend: true,
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot(),
    PushPipe,
    LetDirective,
    StoreRouterConnectingModule.forRoot({ routerState: RouterState.Full }),
    StoreModule.forRoot(appReducers, {
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot(appEffects),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      trace: true,
      logOnly: !isDevMode(),
      traceLimit: 75,
    }),
    HammerModule,
    NbDatepickerModule.forRoot(),
    NbTimepickerModule.forRoot(),
    NbMomentDateModule,
    NbDialogModule.forRoot({ closeOnBackdropClick: false }),
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    CommonBackButtonHistoryModule.forRoot(),
    CommonLoadingModule,
    CommonPwaModule,
    RecaptchaV3Module,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: !window.location.hostname.includes('localhost') }),
    NbInputModule,
    MarkdownModule.forRoot(),
    ZendeskModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, LocalizationService, Injector],
      multi: true,
    },
    LocaleProvider,
    DeviceDetectorService,
    Title,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaV3Key },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
