import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const resetPasswordActions = createActionGroup({
  source: 'Auth - Reset Password',
  events: {
    start: props<{ email: string }>(),
    success: emptyProps(),
    error: emptyProps(),
    resetForm: emptyProps(),
  },
});
