import { createReducer, on } from '@ngrx/store';

import { adminLoginAsOwnerActions, adminLoginAsUserActions, adminLogoutActions } from '../actions/admin.actions';

export type AdminState = {
  isAdminLoggingIn: boolean;
  isAdminLoggingOut: boolean;
};

export const initialAdminState: AdminState = {
  isAdminLoggingOut: false,
  isAdminLoggingIn: false,
};

export const adminReducer = createReducer(
  initialAdminState,
  on(
    adminLoginAsUserActions.start,
    adminLoginAsOwnerActions.start,
    (state): AdminState => ({
      ...state,
      isAdminLoggingIn: true,
    }),
  ),
  on(
    adminLoginAsUserActions.success,
    adminLoginAsUserActions.error,
    adminLoginAsOwnerActions.success,
    adminLoginAsOwnerActions.error,
    (state): AdminState => ({
      ...state,
      isAdminLoggingIn: false,
    }),
  ),
  on(
    adminLogoutActions.start,
    (state): AdminState => ({
      ...state,
      isAdminLoggingOut: true,
    }),
  ),
  on(
    adminLogoutActions.success,
    adminLogoutActions.error,
    (state): AdminState => ({
      ...state,
      isAdminLoggingOut: false,
    }),
  ),
);
