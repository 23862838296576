import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@app/environments/environment';
import {
  POST_V1_BathLoginAsCompanyOwnerParams,
  POST_V1_BathLoginAsCompanyOwnerResponse,
  POST_V1_BathLoginAsUserParams,
} from '@app/models/api/v1/bath/bath.model';

@Injectable()
export class V1_BathService {
  private readonly _http = inject(HttpClient);

  /**
   * For admin to login as company owner using company uuid.
   */
  public loginAsCompanyOwner(params: POST_V1_BathLoginAsCompanyOwnerParams): Observable<POST_V1_BathLoginAsCompanyOwnerResponse> {
    return this._http.post<POST_V1_BathLoginAsCompanyOwnerResponse>(
      `${environment.backendUrl}bath/company/${params.companyUuid}/login-as`,
      {},
    );
  }

  /**
   * For admin to login as company owner using company uuid.
   */
  public loginAsUser(params: POST_V1_BathLoginAsUserParams): Observable<POST_V1_BathLoginAsCompanyOwnerResponse> {
    return this._http.post<POST_V1_BathLoginAsCompanyOwnerResponse>(`${environment.backendUrl}bath/user/${params.userUuid}/login-as`, {});
  }
}
