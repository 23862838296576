<div class="content_wrapper pb-0">
  <div class="row h-100 centered_content" [@fadeInOnEnter]>
    <div class="col-12 col-md-6 justify-content-center d-flex flex-column">
      <h1 class="text-center text-primary text-uppercase">{{'fe.shared.components.not_found_page.title' | translate}}</h1>
      <h5 class="text-primary text-center">
        {{'fe.shared.components.not_found_page.text' | translate}}</h5>
      <button nbButton
              shape="round"
              size="large"
              type="button"
              status="success"
              fullWidth
              [routerLink]="['/','app']"
              class="w-100 my-5">
        {{'fe.shared.components.not_found_page.button_title' | translate}}
      </button>
    </div>
    <div class="col-12 col-md-6 d-flex flex-column justify-content-end">
      <img src="https://assets.website-files.com/60465bd634bfdc569e91577f/60465bd634bfdc6fe7915807_bg_roadmapa.png"
           class="w-100">
    </div>
  </div>
</div>
