import { createReducer, on } from '@ngrx/store';

import Nullable from '@app/models/typescript/nullable.model';
import { loginActions } from '../actions/login.actions';
import { logoutActions } from '../actions/logout.actions';
import { initialPersistanceActions, persistanceActions } from '../actions/persistance.actions';
import { refreshTokenActions } from '../actions/refresh-token.actions';

export type TokenState = {
  accessToken: Nullable<string>;
  refreshToken: Nullable<string>;
};

export const initialTokenState: TokenState = {
  accessToken: null,
  refreshToken: null,
};

export const tokenReducer = createReducer(
  initialTokenState,
  on(
    loginActions.success,
    (state, { response }): TokenState => ({
      ...state,
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
    }),
  ),
  on(
    refreshTokenActions.success,
    (state, { response }): TokenState => ({
      ...state,
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
    }),
  ),
  on(
    initialPersistanceActions.tokensLoaded,
    (state, { accessToken, refreshToken }): TokenState => ({
      ...state,
      accessToken,
      refreshToken,
    }),
  ),
  on(
    persistanceActions.removeTokens,
    (state): TokenState => ({
      ...state,
      accessToken: null,
      refreshToken: null,
    }),
  ),
  on(
    logoutActions.logout,
    (): TokenState => ({
      ...initialTokenState,
    }),
  ),
);
