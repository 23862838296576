/* eslint-disable @typescript-eslint/explicit-function-return-type */

import { createSelector } from '@ngrx/store';

import { V1_AUTH_LOGIN_TYPE } from '@app/models/api/v1/auth/auth.enums';
import { selectAuthState } from '../auth.reducers';

export const selectMFAState = createSelector(selectAuthState, state => state.mfa);

export const selectMFAData = createSelector(selectMFAState, state => state.data);

export const selectMFALoginType = createSelector(selectMFAState, state => state.loginType);

export const selectMFAToken = createSelector(selectMFAData, state => state.token);

export const selectIsSubmittingMFACode = createSelector(selectMFAState, state => state.isSubmittingCode);

export const selectIsResendingMFACode = createSelector(selectMFAState, state => state.isResendingCode);

export const selectIsSkippingMFA = createSelector(selectMFAState, state => state.isSkippingMFA);

export const selectIsSettingUpMFA = createSelector(selectMFAState, state => state.isSettingUpMFA);

export const selectIsMFALoginType = (loginType: V1_AUTH_LOGIN_TYPE) => {
  return createSelector(selectMFALoginType, state => state === loginType);
};
