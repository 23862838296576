import { createActionGroup, props } from '@ngrx/store';

export const notificationActions = createActionGroup({
  source: 'App - Notification',
  events: {
    error: props<{ message?: string; title?: string; translate?: boolean; showDefaultMessage?: boolean }>(),
    success: props<{ message?: string; title?: string; translate?: boolean }>(),
    warning: props<{ message?: string; title?: string; translate?: boolean }>(),
  },
});
