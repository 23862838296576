<nb-card class="slnk-card d-flex w-100"
         [class.h-100]="fullHeight"
         [ngClass]="cardColor">
  <nb-card-header *ngIf="isHeaderDisplayed" [class.p-0]="removeHeaderPadding" [class.py-2]="smallHeaderPadding">
    <ng-content select="slnk-common-card-header">
    </ng-content>
  </nb-card-header>
  <nb-card-body *ngIf="isBodyDisplayed"
                [nbSpinner]="showSpinner"
                [style.overflow]="needSwipe ? 'unset' : 'auto'"
                style="z-index: 1"
                [class.p-0]="removeBodyPadding"
                nbSpinnerStatus="primary">
    <ng-content select="slnk-common-card-body">
    </ng-content>
  </nb-card-body>
  <nb-card-footer *ngIf="isFooterDisplayed">
    <ng-content select="slnk-common-card-footer">
    </ng-content>
  </nb-card-footer>
</nb-card>
