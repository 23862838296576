import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import userflow, { EventAttributes } from 'userflow.js';

import { ThemeStorageService } from '@app/common-modules/common-theme-change/services/theme-storage.service';
import { environment } from '@app/environments/environment';
import { V1AuthUser } from '@app/models/api/v1/auth/auth.model';
import { LANGUAGE_CODE } from '@app/shared/models/localization-lang';
import { LocalizationService } from '@app/shared/services/localization.service';

@Injectable({
  providedIn: 'root',
})
export class UserflowService {
  constructor(
    private deviceService: DeviceDetectorService,
    private translate: TranslateService,
    private themeStorageService: ThemeStorageService,
    private localizationService: LocalizationService,
  ) {}

  private userFlowTokenExist(): boolean {
    return !!environment.userFlowToken;
  }

  public userFlowInit(): void {
    if (this.userFlowTokenExist()) {
      userflow.init(environment.userFlowToken);
    }
  }

  public async userFlowInitAnonymous(): Promise<void> {
    if (this.userFlowTokenExist()) {
      try {
        await userflow.identifyAnonymous({
          device_type: this.deviceService.deviceType,
          locale_code: this.localizationService.getSupportLanguage(this.translate.currentLang as LANGUAGE_CODE),
        });
      } catch {
        /* empty */
      }
    }
  }

  public async registerUserFlowUser(user: V1AuthUser): Promise<void> {
    if (this.userFlowTokenExist()) {
      const userModules = {};
      Object.keys(user.modules).forEach(data => {
        userModules['module_' + data] = user.modules[data];
      });
      const userFlowUserData = {
        app_version: 'v' + environment.appVersionNumber,
        firstname: user.name,
        lastname: user.surname,
        uuid: user.uuid,
        // id: user.uuid,
        device_type: this.deviceService.deviceType,
        created: user.created,
        company_name: user.company.company_name,
        role: user.roles.join(','),
        user_create_age_days: user.created ? moment().diff(moment(user.created), 'days') : null,
        today: moment().format(),
        locale_code: this.localizationService.getSupportLanguage(user.language),
        selected_size: user.company.company_size,
        active_users: user.company.active_members_count,
        package: user.company.licence?.licence_package?.code,
        theme_color: this.themeStorageService.getStoredTheme(),
        ...userModules,
      };
      try {
        await userflow.identify(user.uuid, userFlowUserData);
        // TODO MAYBE MOVE UPDATE VERSION AND USERS LAST ACTIVITY TO DIFFERENT FUNCTION
        await userflow.updateUser({
          app_version: 'v' + environment.appVersionNumber,
        });
        await userflow.group(user.company.uuid, {
          name: user.company.name,
        });
      } catch {
        /* empty */
      }
    }
  }

  public unregisterUserFlow(): void {
    if (this.userFlowTokenExist()) {
      userflow?.reset();
      // test this user flow send() error fix
      userflow.identifyAnonymous();
    }
  }

  public trackEvent(eventName: string, properties: EventAttributes = {}): void {
    if (this.userFlowTokenExist()) {
      userflow.track(eventName, properties);
    }
  }
}
