import { createReducer, on } from '@ngrx/store';

import { holdingActions } from '../actions/holding.actions';

export type HoldingState = {
  isChangingCompany: boolean;
};

export const initialHoldingState: HoldingState = {
  isChangingCompany: false,
};

export const holdingReducer = createReducer(
  initialHoldingState,
  on(
    holdingActions.start,
    (state): HoldingState => ({
      ...state,
      isChangingCompany: true,
    }),
  ),
  on(
    holdingActions.success,
    holdingActions.error,
    (state): HoldingState => ({
      ...state,
      isChangingCompany: false,
    }),
  ),
);
