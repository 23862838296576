<slnk-common-card *ngIf="showAutoRefreshDialogContent; else simpleAppUpdateAlert">
  <!--<slnk-common-card-header>
  </slnk-common-card-header>-->
  <slnk-common-card-body>
    <div class="row py-3">
      <div class="col-12">
        <h5 class="text-center mb-3 text-primary">
          {{'fe.app.new_app_modal.new_app_available.title' |translate}}
        </h5>
        <p class="text-center caption mb-0">
          {{'fe.app.new_app_modal.new_app_available.desc' |translate}}
        </p>
      </div>
      <div class="col-12 text-center my-4">
        <nb-icon icon="sync-alt"
                 class="rotating text-primary"
                 style="font-size: 3rem">
        </nb-icon>
      </div>
      <div class="col-12 mb-3">
        <nb-progress-bar status="primary"
                         [value]="refreshTimer"
                         size="tiny">
        </nb-progress-bar>
      </div>
      <div class="col-12 text-center">
        <button nbButton status="primary"
                ghost
                size="small" (click)="reloadPage()">
          {{'fe.app.new_app_modal.new_app_available.update_now_button' |translate}}
        </button>
      </div>
    </div>
  </slnk-common-card-body>
</slnk-common-card>

<ng-template #simpleAppUpdateAlert>
  <slnk-common-card>
    <!--<slnk-common-card-header>
    </slnk-common-card-header>-->
    <slnk-common-card-body>
      <div class="row">
        <div class="col-12">
          <div class="row no-gutters align-items-center mb-3">
            <div class="col">
              <h6 class="text-center m-0 text-primary">
                {{'fe.app.new_app_modal.new_app_available.new_app_downloaded.title' |translate}}
              </h6>
            </div>
          </div>
          <p class="text-center caption mb-3">
            {{'fe.app.new_app_modal.new_app_available.new_app_downloaded.desc' |translate}}
          </p>
        </div>
        <div class="col-12 text-center">
          <nb-checkbox
            id="checkbox_auto_update"
            [(checked)]="this.isAutoRefreshEnabledLocalStorage"
            (checkedChange)="autoRefreshCheckedChange($event)">
            <label
              for="checkbox_auto_update">{{'fe.app.new_app_modal.new_app_available.new_app_downloaded.enable_auto_refresh' |translate}}</label>
          </nb-checkbox>
        </div>
        <div class="col-12 mb-3" *ngIf="this.showAutoRefreshProgress && this.isAutoRefreshEnabledLocalStorage">
          <nb-progress-bar status="primary"
                           [value]="refreshTimer"
                           size="tiny">
          </nb-progress-bar>
        </div>
        <div class="col-12 text-center">
          <button nbButton status="primary"
                  size="tiny" (click)="reloadPage()">
            {{'fe.app.new_app_modal.new_app_available.update_now_button' |translate}}
          </button>
        </div>
      </div>
    </slnk-common-card-body>
  </slnk-common-card>
</ng-template>
