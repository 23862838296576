<nb-card class="mb-3" [class.card--transparent]="transparent">
  <nb-card-body class="overflow-hidden">
    <div class="row">
      <!-- <div class="col-auto">
         {{backLink}}
       </div>-->
      <div class="col d-flex align-items-center" @displayPageTitleAnimation>
        <button
          *ngIf="backLink"
          nbButton
          ghost
          type="button"
          class="d-none d-sm-flex px-2 mr-1"
          [id]="backButtonId"
          [slnkCommonBackButtonHistory]="backLink"
        >
          <nb-icon icon="arrow-left"></nb-icon>
          {{ 'fe.globals.form.buttons.back' | translate }}
        </button>
        <ng-content select="[headerTitleCol]"></ng-content>
      </div>
      <div class="col-auto" [ngClass]="actionColumnClasses">
        <ng-content select="[headerActionsCol]"></ng-content>
      </div>
    </div>
    <ng-content></ng-content>
  </nb-card-body>
</nb-card>

<!--example-->
<!--
<slnk-common-card-page-header
  [backLink]="defaultBackRoute">
  <div headerTitleCol>

  </div>
  <div headerActionsCol class="row small-gutters">

  </div>
</slnk-common-card-page-header>
-->

