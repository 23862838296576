import { Directive } from '@angular/core';

@Directive({
  selector: '[slnkPageHeaderTitleCol]'
})
export class PageHeaderTitleColDirective {

  constructor() { }

}
