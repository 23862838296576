import { createActionGroup, emptyProps } from '@ngrx/store';

export const verifyEmailActions = createActionGroup({
  source: 'Auth - Verify Email',
  events: {
    start: emptyProps(),
    success: emptyProps(),
    error: emptyProps(),
  },
});
