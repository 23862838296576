import { createReducer, on } from '@ngrx/store';

import { SecuritySettings } from '@app/models/shared/security-settings.model';
import Nullable from '@app/models/typescript/nullable.model';
import { passwordSettingsActions } from '../actions/password-settings.actions';

export type PasswordSettingsState = {
  isLoading: boolean;
  settings: Nullable<SecuritySettings>;
};

export const initialPasswordSettingsState: PasswordSettingsState = {
  isLoading: false,
  settings: null,
};

export const passwordSettingsReducer = createReducer(
  initialPasswordSettingsState,
  on(
    passwordSettingsActions.start,
    (state): PasswordSettingsState => ({
      ...state,
      isLoading: true,
    }),
  ),
  on(
    passwordSettingsActions.error,
    (state): PasswordSettingsState => ({
      ...state,
      isLoading: false,
    }),
  ),
  on(
    passwordSettingsActions.success,
    (state, { response }): PasswordSettingsState => ({
      ...state,
      isLoading: false,
      settings: response,
    }),
  ),
);
