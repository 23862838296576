import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonCardComponent } from './components/common-card/common-card.component';
import { NbButtonModule, NbCardModule, NbIconModule, NbSpinnerModule } from '@nebular/theme';
import { CommonCardBodyComponent } from './components/common-card-body/common-card-body.component';
import { CommonCardFooterComponent } from './components/common-card-footer/common-card-footer.component';
import { CommonCardHeaderComponent } from './components/common-card-header/common-card-header.component';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { CommonCardPageHeaderComponent } from './components/common-card-page-header/common-card-page-header.component';
import { PageHeaderTitleColDirective } from './components/directives/page-header-title-col.directive';
import { PageHeaderActionsColDirective } from './components/directives/page-header-actions-col.directive';
import { CommonBackButtonHistoryModule } from '../common-back-button-history/common-backbutton-history.module';


@NgModule({
  declarations: [
    CommonCardComponent,
    CommonCardBodyComponent,
    CommonCardFooterComponent,
    CommonCardHeaderComponent,
    CommonCardPageHeaderComponent,
    PageHeaderTitleColDirective,
    PageHeaderActionsColDirective
  ],
  imports: [
    CommonModule,
    NbCardModule,
    RouterModule,
    NbSpinnerModule,
    TranslateModule,
    NbIconModule,
    NbButtonModule,
    CommonBackButtonHistoryModule
  ],
  exports: [
    CommonCardComponent,
    CommonCardBodyComponent,
    CommonCardFooterComponent,
    CommonCardHeaderComponent,
    CommonCardPageHeaderComponent
  ]
})
export class CommonCardModule {
}
