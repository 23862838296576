export enum LICENCE_TYPE {
  /*
    AFTER BILLING EDITS:
    package -> regular
    invoice -> annual
    custom + regular -> custom
  */

  CUSTOM = 'custom',
  ANNUAL = 'annual',
  REGULAR = 'regular',
}
