import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { adminReducer, AdminState } from './reducers/admin.reducer';
import { changeLanguageReducer, ChangeLanguageState } from './reducers/change-language.reducer';
import { changePasswordReducer, ChangePasswordState } from './reducers/change-password.reducer';
import { holdingReducer, HoldingState } from './reducers/holding.reducer';
import { loginByTokenReducer, LoginByTokenState } from './reducers/login-by-token.reducer';
import { loginReducer, LoginState } from './reducers/login.reducer';
import { mfaReducer, MFAState } from './reducers/mfa.reducer';
import { passwordSettingsReducer, PasswordSettingsState } from './reducers/password-settings.reducer';
import { resetPasswordReducer, ResetPasswordState } from './reducers/reset-password.reducer';
import { setPasswordReducer, SetPasswordState } from './reducers/set-password.reducer';
import { terminalReducer, TerminalState } from './reducers/terminal.reducer';
import { tokenReducer, TokenState } from './reducers/token.reducer';
import { userReducer, UserState } from './reducers/user.reducer';
import { verifyEmailReducer, VerifyEmailState } from './reducers/verify-email.reducer';

export const authStoreName = 'auth';

export const selectAuthState = createFeatureSelector<AuthState>(authStoreName);

export type AuthState = {
  login: LoginState;
  token: TokenState;
  user: UserState;
  holding: HoldingState;
  admin: AdminState;
  mfa: MFAState;
  verifyEmail: VerifyEmailState;
  resetPassword: ResetPasswordState;
  changePassword: ChangePasswordState;
  setPassword: SetPasswordState;
  passwordSettings: PasswordSettingsState;
  loginByToken: LoginByTokenState;
  changeLanguage: ChangeLanguageState;
  terminal: TerminalState;
};

export const authReducers: ActionReducerMap<AuthState> = {
  login: loginReducer,
  user: userReducer,
  token: tokenReducer,
  holding: holdingReducer,
  admin: adminReducer,
  mfa: mfaReducer,
  verifyEmail: verifyEmailReducer,
  resetPassword: resetPasswordReducer,
  changePassword: changePasswordReducer,
  setPassword: setPasswordReducer,
  passwordSettings: passwordSettingsReducer,
  loginByToken: loginByTokenReducer,
  changeLanguage: changeLanguageReducer,
  terminal: terminalReducer,
};
