import * as Sentry from '@sentry/angular-ivy';
import { BrowserOptions } from '@sentry/browser';

import { DIALOG_CLOSED_WITHOUT_CHANGES_ERROR_NAME } from '@app/configs/dialog-closed-error';
import { environment } from '@app/environments/environment';

const SENTRY_DNS = 'https://5367df9ab787666e4fddd2b7a22a2e5a@o4507842986704896.ingest.de.sentry.io/4507849012084816';

const sharedOptions: BrowserOptions = {
  environment: environment.environmentName,
  release: environment.appVersionNumber,
  dsn: ['localhost', '127.0.0.1'].indexOf(window.location.hostname) > -1 ? null : SENTRY_DNS,
  integrations: [
    Sentry.browserApiErrorsIntegration({
      XMLHttpRequest: false,
    }),
  ],
  ignoreErrors: [
    'ResizeObserver loop limit exceeded',
    /ExpressionChangedAfterItHasBeenCheckedError/,
    /ChunkLoadError/,
    DIALOG_CLOSED_WITHOUT_CHANGES_ERROR_NAME,
  ],
};

export const initSentry = (): void => {
  Sentry.init(sharedOptions);
};
