import { createReducer, on } from '@ngrx/store';

import { navigationActions } from '../actions/navigation.actions';

export type NavigationState = {
  routeLoadingCount: number;
};

export const initialNavigationState: NavigationState = {
  routeLoadingCount: 0,
};

export const navigationReducer = createReducer(
  initialNavigationState,
  on(
    navigationActions.increaseRouteLoadingCount,
    (state): NavigationState => ({
      ...state,
      routeLoadingCount: state.routeLoadingCount + 1,
    }),
  ),
  on(
    navigationActions.decreaseRouteLoadingCount,
    (state): NavigationState => ({
      ...state,
      routeLoadingCount: state.routeLoadingCount > 1 ? state.routeLoadingCount - 1 : 0,
    }),
  ),
);
