import { registerLocaleData } from '@angular/common';
import localeCzech from '@angular/common/locales/cs';
import localeGerman from '@angular/common/locales/de';
import localeEnglish from '@angular/common/locales/en-GB';
import localeSpanish from '@angular/common/locales/es';
import localeFrance from '@angular/common/locales/fr';
import localeHungary from '@angular/common/locales/hu';
import localeItalian from '@angular/common/locales/it';
import localePoland from '@angular/common/locales/pl';
import localeSlovak from '@angular/common/locales/sk';
import { Injectable, Optional, SkipSelf } from '@angular/core';

import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { INITIAL_LANGUAGE } from '@app/config';
import { environment } from '@app/environments/environment';
import { LANGUAGE_CODE, LocalizationLang } from '../models/localization-lang';

@Injectable({
  providedIn: 'root',
})
export class LocalizationService {
  private availableLanguages: LocalizationLang[];
  private _locale: string;

  constructor(@Optional() @SkipSelf() otherInstance: LocalizationService) {
    this.availableLanguages = [
      {
        name: 'English',
        icon: 'gb',
        code: LANGUAGE_CODE.EN,
      },
      {
        name: 'German',
        icon: 'de',
        code: LANGUAGE_CODE.DE,
      },
      {
        name: 'Spanish',
        icon: 'es',
        code: LANGUAGE_CODE.ES,
      },
      {
        name: 'Italian',
        icon: 'it',
        code: LANGUAGE_CODE.IT,
      },
      {
        name: 'Polish',
        icon: 'pl',
        code: LANGUAGE_CODE.PL,
      },
      {
        name: 'Slovak',
        icon: 'sk',
        code: LANGUAGE_CODE.SK,
      },
      {
        name: 'Czech',
        icon: 'cz',
        code: LANGUAGE_CODE.CS,
      },
      {
        name: 'Hungarian',
        icon: 'hu',
        code: LANGUAGE_CODE.HU,
      },
    ];
    if (!environment.production) {
      this.availableLanguages.push(
        {
          name: 'French',
          icon: 'fr',
          code: LANGUAGE_CODE.FR,
        },
        {
          name: 'Testing',
          icon: 'im',
          code: LANGUAGE_CODE.TESTING_LANGUAGE_CODE,
        },
      );
    }
    if (otherInstance) {
      // eslint-disable-next-line no-console
      console.error('LocaleService should have only one instance.');
    }
  }

  private set locale(value: string) {
    this._locale = value;
  }

  public get locale(): string {
    return this._locale || INITIAL_LANGUAGE;
  }

  public getAvailableLanguagesWithoutTesting(): Observable<LocalizationLang[]> {
    return this.getAvailableLanguages().pipe(
      map(languages => {
        return languages.filter(language => language.code !== LANGUAGE_CODE.TESTING_LANGUAGE_CODE);
      }),
    );
  }

  public getAvailableLanguages(): Observable<LocalizationLang[]> {
    return of(this.availableLanguages);
  }

  public registerLocale(languageCode: LANGUAGE_CODE): void {
    if (!languageCode || languageCode === LANGUAGE_CODE.TESTING_LANGUAGE_CODE) {
      return;
    }
    this.locale = languageCode;
    moment.locale(languageCode);
    switch (languageCode) {
      case LANGUAGE_CODE.EN: {
        registerLocaleData(localeEnglish);
        break;
      }
      case LANGUAGE_CODE.SK: {
        registerLocaleData(localeSlovak);
        break;
      }
      case LANGUAGE_CODE.CS: {
        registerLocaleData(localeCzech);
        break;
      }
      case LANGUAGE_CODE.DE: {
        registerLocaleData(localeGerman);
        break;
      }
      case LANGUAGE_CODE.FR: {
        registerLocaleData(localeFrance);
        break;
      }
      case LANGUAGE_CODE.IT: {
        registerLocaleData(localeItalian);
        break;
      }
      case LANGUAGE_CODE.ES: {
        registerLocaleData(localeSpanish);
        break;
      }
      case LANGUAGE_CODE.HU: {
        registerLocaleData(localeHungary);
        break;
      }
      case LANGUAGE_CODE.PL: {
        registerLocaleData(localePoland);
        break;
      }
    }
  }

  public getSupportLanguage(lang: LANGUAGE_CODE): LANGUAGE_CODE {
    if (lang === LANGUAGE_CODE.SK) {
      return LANGUAGE_CODE.CS;
    }
    return lang;
  }
}
