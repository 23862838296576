import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

// added moment duration formatting to moment lib
import 'moment-duration-format';
import { LicenseManager } from 'ag-grid-enterprise';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { initSentry } from './sentry';

initSentry();

if (environment.production) {
  enableProdMode();
}

if (environment.removeAppLogs) {
  if (window) {
    window.console.log = (): void => {
      // Don't log anything.
    };
  }
}
LicenseManager.setLicenseKey(
  'CompanyName=Hardsun s.r.o.,LicensedGroup=Hardsun,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-014482,ExpiryDate=18_January_2023_[v2]_MTY3NDAwMDAwMDAwMA==9319fd297868848a700eeebd423480eb',
);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  // eslint-disable-next-line no-console
  .catch(err => console.error(err));
