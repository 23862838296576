import { NavigationExtras } from '@angular/router';

import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const navigationActions = createActionGroup({
  source: 'App - Navigation',
  events: {
    // Replicates router.navigate interface
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    navigateTo: props<{ commands: any[]; useRelativeRoute?: boolean; extras?: NavigationExtras | undefined }>(),
    reloadCurrentRoute: emptyProps(),

    // Route loading indicator
    listenToRouteChange: emptyProps(),
    increaseRouteLoadingCount: emptyProps(),
    decreaseRouteLoadingCount: emptyProps(),
  },
});
