import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatMap } from 'rxjs';

import { navigationActions } from '@app/store/actions/navigation.actions';
import { logoutActions } from '../actions/logout.actions';
import { persistanceActions } from '../actions/persistance.actions';

@Injectable()
export class LogoutEffects {
  public readonly handleLogoutStandardWithReditect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutActions.logoutWithStandardRedirect),
      concatMap(() => [logoutActions.logout(), navigationActions.navigateTo({ commands: ['/', 'auth', 'login'] })]),
    ),
  );

  public readonly handleLogoutTerminalWithReditect$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutActions.logoutWithTerminalRedirect),
      concatMap(() => [logoutActions.logout(), navigationActions.navigateTo({ commands: ['/', 'terminal', 'user-login'] })]),
    ),
  );

  public readonly handleLogout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutActions.logout),
      concatMap(() => [persistanceActions.removeTokens(), persistanceActions.removeAdminLogin(), persistanceActions.removeTerminal()]),
    ),
  );

  public readonly handleLogoutAndSetPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(logoutActions.logoutSetPassword),
      concatMap(({ token }) => [
        logoutActions.logout(),
        navigationActions.navigateTo({
          commands: ['/', 'auth', 'set-password'],
          extras: {
            queryParams: {
              token,
            },
          },
        }),
      ]),
    ),
  );

  constructor(private actions$: Actions) {}
}
