import { inject } from '@angular/core';
import { CanActivateFn, CanMatchFn, UrlTree } from '@angular/router';

import { Store } from '@ngrx/store';
import { filter, Observable, switchMap, take, tap } from 'rxjs';

import { PermissionService } from '@app/auth/services/permission.service';
import { selectInitialAuthAttempt } from '@app/auth/store/selectors/user.selectors';
import { navigationActions } from '@app/store/actions/navigation.actions';

const canAccessSloneekAdmin = (): (() => Observable<boolean | UrlTree>) => {
  return (): Observable<boolean | UrlTree> => {
    const permissionService = inject(PermissionService);
    const store = inject(Store);
    return store.select(selectInitialAuthAttempt).pipe(
      filter(Boolean),
      take(1),
      switchMap(() =>
        permissionService.isSloneekAdminOrTranslator().pipe(
          take(1),
          tap(canAccess => {
            if (!canAccess) {
              store.dispatch(navigationActions.navigateTo({ commands: ['/', 'app'] }));
            }
          }),
        ),
      ),
    );
  };
};

export const sloneekAdminCanActivateGuard: () => CanActivateFn = canAccessSloneekAdmin;

export const sloneekAdminCanMatchGuard: () => CanMatchFn = canAccessSloneekAdmin;
