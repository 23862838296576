import { createReducer, on } from '@ngrx/store';

import { resetPasswordActions } from '../actions/reset-password.actions';

export type ResetPasswordState = {
  isResettingPassword: boolean;
  emailSent: boolean;
  shouldResetForm: boolean;
};

export const initialResetPasswordState: ResetPasswordState = {
  isResettingPassword: false,
  emailSent: false,
  shouldResetForm: false,
};

export const resetPasswordReducer = createReducer(
  initialResetPasswordState,
  on(
    resetPasswordActions.start,
    (state): ResetPasswordState => ({
      ...state,
      isResettingPassword: true,
      emailSent: false,
    }),
  ),
  on(
    resetPasswordActions.error,
    (state): ResetPasswordState => ({
      ...state,
      isResettingPassword: false,
    }),
  ),
  on(
    resetPasswordActions.success,
    (state): ResetPasswordState => ({
      ...state,
      isResettingPassword: false,
      emailSent: true,
      shouldResetForm: true,
    }),
  ),
  on(
    resetPasswordActions.resetForm,
    (state): ResetPasswordState => ({
      ...state,
      shouldResetForm: false,
    }),
  ),
);
