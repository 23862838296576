import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { V1_AuthModule } from '@app/api/v1/auth/v1-auth.module';
import { V1_BathModule } from '@app/api/v1/bath/v1-bath.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { ToastHandlerInterceptor } from './interceptors/toast-handler.interceptor';
import { UserLocaleInterceptor } from './interceptors/user-locale.interceptor';
import { PermissionService } from './services/permission.service';
import { PersistanceService } from './services/persistance.service';
import { authEffects } from './store/auth.effects';
import { authReducers, authStoreName } from './store/auth.reducers';

@NgModule({
  imports: [V1_AuthModule, V1_BathModule, StoreModule.forFeature(authStoreName, authReducers), EffectsModule.forFeature(authEffects)],
  providers: [
    PersistanceService,
    PermissionService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: UserLocaleInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ToastHandlerInterceptor, multi: true },
  ],
})
export class AuthCoreModule {}
