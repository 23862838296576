import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authCanActivateGuard, authCanMatchGuard } from '@app/auth/guards/auth.guard';
import { sloneekAdminCanActivateGuard, sloneekAdminCanMatchGuard } from './admin/guards/sloneek-admin-or-translator.guard';
import { anonymousCanActivateGuard, anonymousCanMatchGuard } from './auth/guards/anonymous.guard';
import { notTerminalCanActivateGuard, notTerminalCanMatchGuard } from './auth/guards/is-not-terminal.guard';
import { AuthPageUnderConstructionComponentComponent } from './auth-page-under-construction-component/auth-page-under-construction-component.component';
import { NotFoundLayoutPageComponent } from './not-found-page/pages/not-found-layout-page/not-found-layout-page.component';
import { PUBLIC_ROUTES } from './public/public.routes.misc';

const routes: Routes = [
  {
    path: 'admin',
    canActivate: [authCanActivateGuard, notTerminalCanActivateGuard(), sloneekAdminCanActivateGuard()],
    canMatch: [authCanMatchGuard(), notTerminalCanMatchGuard(), sloneekAdminCanMatchGuard()],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
  },
  // UNCOMMENT TO ENABLE UNDER CONSTRUCTION MODE
  // {path: '**', redirectTo: 'under-construction', pathMatch: 'full'},
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth-lazy.module').then(m => m.AuthLazyModule),
  },
  {
    path: 'app',
    canActivate: [authCanActivateGuard(), notTerminalCanActivateGuard()],
    canMatch: [authCanMatchGuard(), notTerminalCanMatchGuard()],
    loadChildren: () => import('./sloneek/sloneek.module').then(m => m.SloneekModule),
  },
  {
    path: 'terminal',
    loadChildren: () => import('./terminal/terminal.module').then(m => m.TerminalModule),
  },
  {
    path: 'external-user',
    loadChildren: () => import('./external-user/external-user.module').then(m => m.ExternalUserModule),
  },
  {
    path: PUBLIC_ROUTES.PUBLIC,
    loadChildren: () => import('./public/public.module').then(m => m.PublicModule),
  },
  {
    path: 'pre-boarding',
    loadChildren: () => import('./pre-hired/pre-hired.module').then(m => m.PreHiredModule),
    canMatch: [anonymousCanMatchGuard()],
    canActivate: [anonymousCanActivateGuard()],
  },
  {
    path: 'candidate',
    loadChildren: () => import('./ats-candidate/ats-candidate.module').then(m => m.AtsCandidateModule),
    canMatch: [anonymousCanMatchGuard()],
    canActivate: [anonymousCanActivateGuard()],
  },
  {
    path: '404',
    component: NotFoundLayoutPageComponent,
  },
  { path: '', redirectTo: 'app', pathMatch: 'full' },
  // UNDER CONSTRUCTION
  {
    path: 'under-construction',
    component: AuthPageUnderConstructionComponentComponent,
  },
  {
    path: '**',
    redirectTo: '404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
