import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'slnk-common-loading',
  templateUrl: './common-loading.component.html',
  styleUrls: ['./common-loading.component.scss'],
})
export class CommonLoadingComponent {
  @Input() public isPrimary: boolean;
  @HostBinding('class.absoluteLoading') @Input() public absolute = false;
}
