import { AdminEffects } from './effects/admin.effects';
import { ChangeLanguageEffects } from './effects/change-language.effects';
import { ChangePasswordEffects } from './effects/change-password.effects';
import { HoldingEffects } from './effects/holding.effects';
import { LoginByTokenEffects } from './effects/login-by-token.effects';
import { LoginEffects } from './effects/login.effects';
import { LogoutEffects } from './effects/logout.effects';
import { MFAEffects } from './effects/mfa.effects';
import { PasswordSettingsEffects } from './effects/password-settings.effects';
import { PersistanceEffects } from './effects/persistance.effects';
import { RefreshTokenEffects } from './effects/refresh-token.effects';
import { ResetPasswordEffects } from './effects/reset-password.effects';
import { SetPasswordEffects } from './effects/set-password.effects';
import { TerminalEffects } from './effects/terminal.effects';
import { UserInfoEffects } from './effects/user-info.effects';
import { VerifyEmailEffects } from './effects/verify-email.effects';

export const authEffects = [
  UserInfoEffects,
  LoginEffects,
  LogoutEffects,
  PersistanceEffects,
  RefreshTokenEffects,
  HoldingEffects,
  AdminEffects,
  MFAEffects,
  VerifyEmailEffects,
  ResetPasswordEffects,
  ChangePasswordEffects,
  LoginByTokenEffects,
  SetPasswordEffects,
  PasswordSettingsEffects,
  ChangeLanguageEffects,
  TerminalEffects,
];
