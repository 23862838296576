import { createReducer, on } from '@ngrx/store';

import { changeLanguageActions } from '../actions/change-language.actions';

export type ChangeLanguageState = {
  isChangingLanguage: boolean;
};

export const initialChangeLanguageState: ChangeLanguageState = {
  isChangingLanguage: false,
};

export const changeLanguageReducer = createReducer(
  initialChangeLanguageState,
  on(
    changeLanguageActions.start,
    (state): ChangeLanguageState => ({
      ...state,
      isChangingLanguage: true,
    }),
  ),
  on(
    changeLanguageActions.success,
    changeLanguageActions.error,
    (state): ChangeLanguageState => ({
      ...state,
      isChangingLanguage: false,
    }),
  ),
);
