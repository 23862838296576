import { createReducer, on } from '@ngrx/store';

import { setPasswordActions } from '../actions/set-password.actions';

export type SetPasswordState = {
  isSettingPassword: boolean;
  passwordSet: boolean;
  shouldResetForm: boolean;
};

export const initialSetPasswordState: SetPasswordState = {
  isSettingPassword: false,
  passwordSet: false,
  shouldResetForm: false,
};

export const setPasswordReducer = createReducer(
  initialSetPasswordState,
  on(
    setPasswordActions.start,
    (state): SetPasswordState => ({
      ...state,
      isSettingPassword: true,
      passwordSet: false,
    }),
  ),
  on(
    setPasswordActions.error,
    (state): SetPasswordState => ({
      ...state,
      isSettingPassword: false,
    }),
  ),
  on(
    setPasswordActions.success,
    (state): SetPasswordState => ({
      ...state,
      isSettingPassword: false,
      passwordSet: true,
      shouldResetForm: true,
    }),
  ),
  on(
    setPasswordActions.resetForm,
    (state): SetPasswordState => ({
      ...state,
      shouldResetForm: false,
    }),
  ),
);
