import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs';

import { logoutActions } from '../actions/logout.actions';
import { persistanceActions } from '../actions/persistance.actions';
import { refreshTokenActions } from '../actions/refresh-token.actions';

@Injectable()
export class RefreshTokenEffects {
  public readonly handleFailure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshTokenActions.error),
      map(() => logoutActions.logoutWithStandardRedirect()),
    ),
  );

  public readonly persistTokens$ = createEffect(() =>
    this.actions$.pipe(
      ofType(refreshTokenActions.success),
      map(({ response: { refresh_token, access_token } }) =>
        persistanceActions.persistTokens({ tokens: { refreshToken: refresh_token, accessToken: access_token } }),
      ),
    ),
  );

  constructor(private actions$: Actions) {}
}
