import { Injectable } from '@angular/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import moment from 'moment';
import { NavigationEnd } from '@angular/router';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SlnkGoogleTagManagerService {

  constructor(
    private gtmService: GoogleTagManagerService
  ) {
    if (!environment.production) {
      // DISABLED GTM ON DEVELOPMENT ENV
      // TODO TEST THIS
      // @ts-ignore
      this.gtmService.config = null;
      // this.gtmService.googleTagManagerConfiguration = null;
    }
  }

  public sendOnBoardingDataToGoogleTagManager(category: string,
                                              action: string,
                                              label: string | number) {
    const event = 'onboarding';
    if (environment.production) {
      this.gtmService.getDataLayer().push({
        event,
        e_category: category,
        e_action: action,
        e_label: label,
      });
    }
  }

  pushEvent(event) {
    if (environment.production) {
      this.gtmService.pushTag(
        {
          event,
          eventCategory: 'category',
          eventAction: 'action'
        }
      );
    }
  }

  public sendGtmEvent(event: string,
                      category?: string,
                      action?: string,
                      label?: string | number) {
    if (!label) {
      label = moment().utc().unix();
    }
    if (environment.production) {
      this.gtmService.getDataLayer().push({
        event,
        e_category: category,
        e_action: action,
        e_label: label,
      });
    }
  }

  public routerNavigationTracking(event: NavigationEnd) {
    const gtmTag = {
      event: 'page',
      pageName: event.url
    };
    if (environment.production) {
      this.gtmService.pushTag(gtmTag).catch(e => {
      });
    }
  }

}
